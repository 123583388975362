import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import HelperBilling from '../../helper/Billing';
import HelperEmptyImg from '../../helper/EmptyLarge';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: global.userName,
      done: false,
      num: global.num,
      sessions: [],
      notifications: [],
      events: [],
      subscriptions: global.subscriptions,
    };
  }


  componentDidMount() {
    global.activityLocked = false
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id !== 'home' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.welcomepage')+' - '+global.userBusiness
    window.scrollTo(0, 0)
    global.programLocked = false

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('eventsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('habitsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.welcomepage')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var tmp = []
    var tmp2 = []
    var tmp3 = []

    for(var hab of global.habits) {
      if(Moment(hab.data.start, 'X').startOf('day') < Moment() && Moment(hab.data.end, 'X') > Moment() && global.spaceAllowHabits) {
        var add = true
        if(hab.data.days !== undefined) {
          for(var day of hab.data.days) {
            if(day.indexOf(Moment().format('DD/MM/YYYY')) !== -1) {
              add = false
            }
          }
        }
        if(add) {
          tmp2.push({
            icon: 'habit',
            color: 'quarternary',
            title: hab.data.name,
            line1: hab.data.amount+' '+hab.data.unit+' '+(hab.data.interval === 1 ? '::overview:label.perday::' : '::overview:label.perweek::'),
            line2: '::overview:not.habit.line2::',
            link: '/'+global.uid+'/health/habit/'+hab.id,
            date: Moment().format('X')
          })
        }
      }
    }

    if(global.userParent === '' || global.userParent === undefined) {
      for(var sub of this.state.subscriptions) {
        var next = Moment(sub.data.next, 'X')
        var paused = false
        if(sub.data.pause !== undefined) {
          if(sub.data.pause !== '') {
            paused = true
          }
        }
        if(sub.data.status === 'unpaid' || sub.data.status === 'past_due') {
          tmp2.push({
            icon: 'card',
            color: 'red',
            title: '::overview:not.membership.title1::',
            line1: sub.data.name+' ('+HelperBilling.showAmount(sub.data.price/100)+' '+HelperBilling.getCycle(sub)+')',
            line2: '::overview:not.membership.line2::',
            link: 'chat',
            date: Moment().format('X')
          })
        }
        if(next.isAfter(Moment()) && next.isBefore(Moment().add(7, 'days')) && sub.data.status === 'active' && !paused && sub.id !== 'trial') {
          tmp2.push({
            icon: 'card',
            color: 'secondary',
            title: '::overview:not.membership.title2::',
            line1: Moment(sub.data.next, 'X').locale(lang.language).format('ddd, D MMM YYYY'),
            line2: sub.data.name+' - '+HelperBilling.showAmount(sub.data.price/100)+' '+HelperBilling.getCycle(sub),
            link: 'chat',
            date: Moment().format('X')
          })
        }
        if(next.isAfter(Moment()) && next.isBefore(Moment().add(7, 'days')) && sub.data.status === 'active' && !paused && sub.id === 'trial') {
          tmp2.push({
            icon: 'card',
            color: 'secondary',
            title: '::overview:not.trial.title::',
            line1: '::overview:not.trial.line1::',
            line2: Moment(sub.data.next, 'X').locale(lang.language).format('ddd, D MMM YYYY'),
            link: 'chat',
            date: Moment().format('X')
          })
        }
      }
    }

    for(var item of global.sessions) {
      var checkComm = false
      var date = Moment(item.data.timestamp, 'X').add(item.data.duration, 'minutes')
      if(global.loc === '' || item.data.location === global.loc) {
        if(item.data.group) {
          if(item.data.clients !== undefined) {
            if(item.data.clients.indexOf(global.spaceClient) !== -1 && date > Moment()) {
              tmp.push(item)
              checkComm = true
            }
          }
        } else {
          if(item.data.client === global.spaceClient && date > Moment()) {
            tmp.push(item)
            checkComm = true
          }
        }
        if(checkComm) {
          for(var comm of item.comments) {
            if(comm.data.sender !== global.uidUser && comm.data.sender !== global.spaceClient) {
              tmp2.push({
                icon: 'comment',
                color: 'primary',
                title: '::overview:not.comment.title::',
                line1: this.renderSessionName(item),
                line2: this.renderSessionDate(item.data.timestamp)+' '+Moment(item.data.timestamp, 'X').locale(lang.language).format('LT'),
                link: '/'+global.uid+'/session/'+item.id,
                date: Moment(comm.data.date, 'X').format('X')
              })
            }
          }
        }
      }
    }

    for(var item2 of global.events) {
      var date2 = Moment(item2.data.timestamp, 'X').add(item2.data.duration, 'minutes')
      if(global.loc === '' || item.data.location === global.loc) {
        if(item2.data.clients !== undefined) {
          if(item2.data.clients.indexOf(global.spaceClient) !== -1 && date2 > Moment()) {
            tmp3.push(item2)
          }
        }
      }
    }

    if(global.userParent === '' || global.userParent === undefined) {
      // Invoices
      for(var inv of global.invoices) {
        if(inv.data.client === global.spaceClient && inv.data.status === 'open') {
          tmp2.push({
            icon: 'invoice',
            color: 'tertiary',
            title: '::overview:not.invoice.title::',
            line1: HelperBilling.showAmount(inv.data.price),
            line2: lang.t('overview:label.due')+' '+ Moment(inv.data.due, 'X').locale(lang.language).format('D MMM YYYY'),
            link: '/'+global.uid+'/admin/invoice/'+inv.id,
            date: Moment(inv.data.date, 'X').format('X')
          })
        }
      }
      // Packs
      var num1 = this.getUnpaid(false)
      var num2 = this.getUnpaid(true)
      var link = 'chat'
      var line2 = '::overview:not.unpaid.line2a::'
      var adder = ''
      if(global.userStripeConnect !== '' && global.products.length > 0) {
        link = '/'+global.uid+'/admin/payment'
        line2 = '::overview:not.unpaid.line2b::'
        adder = ' '+'::overview:not.unpaid.payment::'
      }
      if(num1 > 0) {
        tmp2.push({
          icon: 'card',
          color: 'red',
          title: '::overview:not.unpaid.title::',
          line1: num1+' '+'::overview:not.unpaid.line1::',
          line2: line2,
          link: link,
          date: Moment().format('X')
        })
      }
      if(num1 < -1) {
        tmp2.push({
          icon: 'card',
          color: 'secondary',
          title: '::overview:not.session1.title1::'+' '+String(-num1)+' '+'::overview:not.session1.title2::',
          line1: '::overview:not.session1.line1::',
          line2: '::overview:not.session1.line2::',
          link: link,
          date: Moment().format('X')
        })
      }
      var needed1 = this.getUnpaidNeeded(false)
      if(num1 === -1 || (num1 === 0 && global.subscriptions.length === 0 && needed1)) {
        var label = '::overview:not.session2.title1::'
        if(num1 === 0) {
          label = '::overview:not.session2.title2::'
        }
        tmp2.push({
          icon: 'card',
          color: 'yellow',
          title: label,
          line1: '::overview:not.session2.line1::'+adder,
          line2: '::overview:not.session2.line2::',
          link: link,
          date: Moment().format('X')
        })
      }
      if(num2 > 0) {
        tmp2.push({
          icon: 'card',
          color: 'red',
          title: '::overview:not.unpaidgroup.title::',
          line1: num2+' '+'::overview:not.unpaid.line1::',
          line2: line2,
          link: link,
          date: Moment().format('X')
        })
      }
      if(num2 < -1) {
        tmp2.push({
          icon: 'card',
          color: 'secondary',
          title: '::overview:not.group1.title1::'+' '+String(-num2)+' '+'::overview:not.group1.title2::',
          line1: '::overview:not.session1.line1::',
          line2: '::overview:not.session1.line2::',
          link: link,
          date: Moment().format('X')
        })
      }
      var needed2 = this.getUnpaidNeeded(true)
      if(num2 === -1 || (num2 === 0 && global.subscriptions.length === 0 && needed2)) {
        var label2 = '::overview:not.group2.title1::'
        if(num2=== 0) {
          label2 = '::overview:not.group2.title2::'
        }
        tmp2.push({
          icon: 'card',
          color: 'yellow',
          title: label2,
          line1: '::overview:not.session2.line1::'+adder,
          line2: '::overview:not.group2.line2::',
          link: link,
          date: Moment().format('X')
        })
      }
    }
    // Notifications
    //unpaid, pt, group - how was?, comment
    if(!global.userVerified) {
      tmp = []
    }

    this.setState({
      name: global.userName,
      sessions: tmp,
      notifications: tmp2,
      events: tmp3,
      subscriptions: global.subscriptions,
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }



  getUnpaid(group) {
    var num = 0
    for(var item of global.credits) {
      if(item.data.group === group) {
        var tmp = item.data.sessionsTotal-item.data.sessionsPaid
        if(item.data.expires === undefined) {
          num += tmp
        } else {
          if(Moment(item.data.expires, 'X') > Moment()) {
            num += tmp
          }
        }
      }
    }
    for(var sub of global.subscriptions) {
      var tot = 0
      if(sub.data.group === group && sub.data.sessions > 0) {
        tot = sub.data.sessions
        for(var item2 of global.sessions) {
          if(item2.data.group === group && item.data.attendance === 3 && Moment().format('MM/YYYY') === Moment(item2.data.timestamp, 'X').format('MM/YYYY')) {
            tot -= 1
          }
        }
      }
      if(tot > 0) {
        num -= tot
      }
    }
    for(var sub2 of global.subscriptions) {
      if(sub2.data.group === group && sub2.data.sessions === 0) {
        num = 0
      }
    }
    return num
  }


  getUnpaidNeeded(group) {
    var result = true
    var n1 = 0
    var n2 = 0
    for(var item of global.credits) {
      if(item.data.group === group) {
        if(item.data.expires === undefined) {
          n1 += item.data.sessionsPaid
          n2 += item.data.sessionsTotal
        } else {
          if(Moment(item.data.expires, 'X') > Moment()) {
            n1 += item.data.sessionsPaid
            n2 += item.data.sessionsPaid
          }
        }
      }
    }
    for(var sub2 of global.subscriptions) {
      if(sub2.data.group === group && sub2.data.sessions === 0) {
        n1 = 0
        n2 = 0
      }
    }
    if(n1 === 0 && n2 === 0) {
      result = false
    }
    return result
  }


  clickNotification(link) {
    if(link === 'chat') {
      EventEmitter.dispatch('chatRequest', 'loaded');
    } else {
      this.props.history.push(link)
    }
  }


  convertString(label) {
    var narr = []
    if(label.indexOf('::') !== -1) {
      var arr = label.split('::')
      for(var a of arr) {
        if(a.indexOf('overview:') !== -1) {
          narr.push(lang.t(a))
        } else {
          narr.push(a)
        }
      }
      label = ""
      for(var a2 of narr) {
        label += a2
      }
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderName() {
    if(this.state.name !== '' && this.state.name !== null) {
      const ar = this.state.name.split(' ')
      return ar[0]
    }
  }


  renderSessionType(item) {
    if(item.data.group && item.data.availability === undefined) {
      return (
        <div className="icon primary">
          <div className="inner group"></div>
        </div>
      )
    } else {
      if(item.data.uid === global.uidUser) {
        return (
          <div className="icon tertiary">
          <div className="inner training"></div>
        </div>
        )
      } else {
        return (
          <div className="icon secondary">
          <div className="inner session"></div>
        </div>
        )
      }
    }
  }


  renderSessionName(item) {
    var label = lang.t('calendar:label.pt')
    if(item.data.name !== undefined && item.data.name !== '') {
      label = item.data.name
    }
    if(item.data.group) {
      if(item.data.availability === undefined) {
        if(item.data.client === '') {
          label = lang.t('calendar:label.group')
        } else {
          label = item.data.client
        }
      } else {
        label = lang.t('calendar:label.available')
        if(item.data.name !== undefined && item.data.name !== '') {
          label = item.data.name
        }
      }
    }
    if(item.data.link !== '') {
      label += ' ('+lang.t('calendar:label.virtual')+')'
    }
    return label
  }


  renderSessionDate(date) {
    var label = Moment(date, 'X').locale(lang.language).format('dddd, D MMM YYYY')
    if(Moment(date, 'X').isSame(Moment(), 'day')) {
      label = lang.t('calendar:label.today2')
    }
    if(Moment(date, 'X').add(1, 'day').isSame(Moment(), 'day')) {
      label = lang.t('calendar:label.tomorrow2')
    }
    return label
  }


  renderSessionInfo(item) {
    if(item.data.locationName === undefined) {
      return <p className="lft"><strong>{this.renderSessionName(item)}</strong><br/>{this.renderSessionDate(item.data.timestamp)}<br/>{Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')}</p>
    } else {
      return <p className="lft"><strong>{this.renderSessionName(item)}</strong><br/>{this.renderSessionDate(item.data.timestamp)} - {Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')}<br/>{item.data.locationName}</p>
    }
  }


  renderEventInfo(item) {
    if(item.data.locationName === undefined) {
      return <p className="lft"><strong>{item.data.title}</strong><br/>{this.renderSessionDate(item.data.timestamp)}<br/>{Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')}</p>
    } else {
      return <p className="lft"><strong>{item.data.title}</strong><br/>{this.renderSessionDate(item.data.timestamp)} - {Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')}<br/>{item.data.locationName}</p>
    }
  }


  renderSessions() {
    if(this.state.sessions.length === 0 && this.state.events.length === 0) {
      return (
        <div className="empty large pt-60 clear mb-30">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('calendar')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:upcoming.title')}</h3>
            <p className="mb-30" style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:upcoming.text')}</p>
            <Link to={'/'+global.uid+'/calendar'} className="btn tertiary width-12">{lang.t('overview:button.gotocalendar')}</Link>
          </div>
        </div>
      )
    } else {
      var list = this.state.sessions.sort((a,b) => Moment(a.data.timestamp, 'X') - Moment(b.data.timestamp, 'X'))
      var list2 = this.state.events.sort((a,b) => Moment(a.data.timestamp, 'X') - Moment(b.data.timestamp, 'X'))
      return (
        <div>
          {list.map(item => (
            <div className="box list highlight mb-10" key={item.id}>
              <Link to={'/'+global.uid+'/session/'+item.id}>
                <div className="col-10">
                  {this.renderSessionType(item)}
                  {this.renderSessionInfo(item)}
                  <div className="clear"></div>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
          {list2.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/'+global.uid+'/event/'+item.id}>
                <div className="col-10">
                  <div className="icon quarternary">
                    <div className="inner event"></div>
                  </div>
                  {this.renderEventInfo(item)}
                  <div className="clear"></div>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderNotButton(item) {
    if(item.link.indexOf('payment') !== -1) {
      return (
        <button className="btn tertiary small rgt">{lang.t('overview:button.topup')}</button>
      )
    } else {
      return (
        <button className="btn tertiary small rgt">{lang.t('common:button.view')}</button>
      )
    }
  }


  renderNotifications() {
    if(this.state.notifications.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('activity')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:activity.text')}</h3>
            <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:activity.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.notifications.sort((a,b) => Moment(b.date, 'X') - Moment(a.date, 'X'))
      return (
        <div>
          {list.map(item => (
            <div className="box list highlight mb-10" key={item.id} onClick={() => this.clickNotification(item.link)}>
              <div className="col-10">
                <div className={'icon '+item.color}>
                  <div className={'inner '+item.icon}></div>
                </div>
                <p className="lft"><strong>{this.convertString(item.title)}</strong><br/>{this.convertString(item.line1)}<br/>{this.convertString(item.line2)}</p>
                <div className="clear"></div>
              </div>
              <div className="col-2">
                {this.renderNotButton(item)}
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderNav() {
    if(global.userCommunity) {
      return (
        <div>
          <Link to={'/'+global.uid+'/home'} className="tab active">
            <p>{lang.t('nav:home.overview')}</p>
            <div className="bg"></div>
          </Link>
          <Link to={'/'+global.uid+'/community'} className="tab">
            <p>{lang.t('nav:home.community')}</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div>
          <Link to={'/'+global.uid+'/home'} className="tab active">
            <p>{lang.t('nav:home.overview')}</p>
            <div className="bg"></div>
          </Link>
          <Link to={'/'+global.uid+'/calendar'} className="tab">
            <p>{lang.t('nav:home.calendar')}</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.welcome')} {this.renderName()}</h2>
              {this.renderNav()}
            </div>
            <div className="clear"></div>
          </div>
          <div className="listheader">
            <h3>{lang.t('header:home.events')}</h3>
            <div className="clear"></div>
          </div>
          {this.renderSessions()}

          <div className="listheader">
            <h3>{lang.t('header:home.notifications')}</h3>
            <div className="clear"></div>
          </div>
          {this.renderNotifications()}
          <div className="clear sv-40"></div>
        </div>
        <Navigation active='overview' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','common','overview','calendar','empty'])(withRouter(withAuthorizationMember(condition)(MemberHomePage)));