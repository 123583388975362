import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import HelperClients from '../../helper/Clients';
import HelperEmpty from '../../helper/Empty';
import HelperEmptyImg from '../../helper/EmptyLarge';
import Navigation from '../../components/Navigation';
import ModalBlock from '../../components/Modals/block';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberSessionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      done: false,
      num: global.num,
      writeComment: false,
      comment: '',
      editComment: '',
      showOverlay: false,
      hidden: 'hidden',
      showOverlayDelete: false,
      hiddenDelete: 'hidden',
      showOverlaySessions: false,
      hiddenSessions: 'hidden',
      showOverlayRecurring: false,
      hiddenRecurring: 'hidden',
      showOverlayRecurringCancel: false,
      hiddenRecurringCancel: 'hidden',
      margin: 0,
      origin: 'calendar',
      current: global.spaceClient,
      currentn: lang.t('common:label.you'),
      showModalBlock: false,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var sid = arr[arr.length-1]
    if(id !== 'session' && id !== 'member') {
      global.uid = id
    }
    if(arr[arr.length-2] === 'past-session') {
      this.setState({
        origin: 'training'
      })
    }
    Connector.setUser()
    document.title = lang.t('header:title.session')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true,
      id: sid
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('recurringLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.session')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var arr = this.props.location.pathname.split('/')
    var sid = arr[arr.length-1]
    var item = null
    if(sid !== 'session' && sid !== 'member') {
      for(var sess1 of global.sessions) {
        if(sess1.id === sid) {
          item = sess1
        }
      }
      for(var sess2 of global.sessionsTraining) {
        if(sess2.id === sid) {
          item = sess2
        }
      }
    }
    this.setState({
      forms: global.forms,
      item: item,
      id: sid
    })
  }


  getSessionName() {
    var label = lang.t('calendar:label.pt')
    if(this.state.item !== null) {
      if(this.state.item.data.name !== undefined && this.state.item.data.name !== '') {
        label = this.state.item.data.name
      }
      if(this.state.item.data.group) {
        if(this.state.item.data.availability === undefined) {
          if(this.state.item.data.client === '') {
            label = lang.t('calendar:label.group')
          } else {
            label = this.state.item.data.client
          }
        } else {
          label = lang.t('calendar:label.available')
          if(this.state.item.data.name !== undefined && this.state.item.data.name !== '') {
            label = this.state.item.data.name
          }
        }
      }
      if(this.state.item.data.uid === global.uidUser) {
        label = lang.t('calendar:label.training')
      }
    }
    
    return label
  }


  getSessionTime() {
    var label = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM - LT')
    var tdy = Moment().format('DD/MM/YYYY')
    var tmr = Moment().add(1, 'days').format('DD/MM/YYYY')
    var dt = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    if(dt === tdy) {
      label = lang.t('calendar:label.today2')+' '+Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(dt === tmr) {
      label = lang.t('calendar:label.tomorrow2')+' '+Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }


  // Comments ------------------------------------------------------------



  onChangeComment = event => {
    this.setState({comment: event.target.value});
  };


  sendComment(key) {
    if(this.state.comment !== "") {
      if(key === '') {
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/comments').push({
          sender: global.spaceClient,
          date: parseInt(Moment().format('X')),
          text: this.state.comment
        }).then((data)=>{
          this.setState({
            comment: '',
            writeComment: false
          })
          EventEmitter.dispatch('showMessage', lang.t('message:comment'));
          var name = lang.t('calendar:label.group')
          if(this.state.item.data.client !== '') {
            name = this.state.item.data.client
          }
          if(!this.state.item.data.group) {
            name = "the 1:1 session"
          }
          if(global.clientToken !== '' && global.clientToken !== undefined) {
            Connector.sendPushNotification(global.clientToken, lang.t('message:push.comment.title'), global.userName+' '+lang.t('message:push.comment.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
          }
          if(this.state.item.data.group) {
            var sent = []
            
            for(var item of global.clients) {
              if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
                if(this.state.item.data.clients !== undefined) {
                  if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.clients.indexOf(item.id) !== -1 && item.id !== global.spaceClient) {
                    Connector.sendPushNotification(item.data.pushToken, lang.t('message:push.comment.title'), global.userName+' '+lang.t('message:push.comment.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
                    sent.push(item.data.pushToken)
                  }
                }
                if(this.state.item.data.waiting !== undefined) {
                  if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.waiting.indexOf(item.id) !== -1 && item.id !== global.spaceClient) {
                    Connector.sendPushNotification(item.data.pushToken, lang.t('message:push.comment.title'), global.userName+' '+lang.t('message:push.comment.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
                    sent.push(item.data.pushToken)
                  }
                }
              }
            }
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      } else {
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/comments/'+key).update({
          sender: global.spaceClient,
          text: this.state.comment
        }).then((data)=>{
          this.setState({
            comment: '',
            editComment: ''
          })
          EventEmitter.dispatch('showMessage', lang.t('message:commentupdate'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }  
    }
  }


  getCommenter(id) {
    var label = lang.t('common:label.member')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    if(id === global.uidUser || id === global.spaceClient) {
      label = lang.t('common:label.you')
    }
    if(id === global.uid) {
      label = global.spaceOwner
    }
    return label
  }


  getCommentImage(id) {
    var client = null;
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    if(client !== null) {
      if(client.data.image === '') {
        var inits = ''
        let arr = client.data.name.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        return (
          <div className="avatar">
            <p>{inits}</p>
          </div>
        )
      } else {
        var img = ''
        for(var item2 of global.clientsImages) {
          if(item2.id === client.id) {
            img = item2.image
          }
        }
        return (
          <div className="avatar" style={{backgroundImage: 'url('+img+')'}}> </div>
        )
      }
    } else {
      if(id === global.uidUser) {
        if(global.userImage === '' && global.userName !== null) {
          var inits2 = ''
          let arr = global.userName.split(' ')
          if(arr.length > 1) {
            inits2 = arr[0].charAt(0)+arr[1].charAt(0)
          } else {
            inits2 = arr[0].charAt(0)
          }
          return (
            <div className="avatar">
              <p>{inits2}</p>
            </div>
          )
        } else {
          return (
            <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
          )
        }
      }
      if(id === global.uid) {
        return (
          <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
        )
      }
    }
  }


  calculateRating(num) {
    var width = 0
    var number = 0
    if(this.state.item.data.rating !== undefined) {
      for(var item of this.state.item.data.rating) {
        var arr = item.split(',')
        number += parseInt(arr[1])
      }
      var total = number/(this.state.item.data.rating.length*5)*100
      if(total >= 20*num) {
        width = 30
      } else {
        var tmp = (20*num)-(total+20)
        width = (tmp/100)*30
      }
    }
    return width
  }


  getRating() {
    var label = ''
    var number = 0
    if(this.state.item.data.rating !== undefined) {
      for(var item of this.state.item.data.rating) {
        var arr = item.split(',')
        number += parseInt(arr[1])
      }
      var total = number/(this.state.item.data.rating.length*5)*100
      label = (total/20).toFixed(1)+" ("+this.state.item.data.rating.length+")"
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    return client
  }


  getClientName(id) {
    var label = lang.t('common:label.member')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }



  // Interactive booking ------------------------------------------------------------



  addBooked(item, name, type) {
    var available = true
    var num = 0
    var products = []
    if(this.state.item.data.memberships !== undefined) {
      products = this.state.item.data.memberships
    }
    for(var cred of global.credits) {
      if(products.length === 0 || products.indexOf(cred.data.products) !== -1) {
        if(cred.data.group && (cred.data.account === undefined || cred.data.account === '' || cred.data.account === global.spaceClient)) {
          var tmp = cred.data.sessionsTotal-cred.data.sessionsPaid
          if(cred.data.expires === undefined) {
            num += tmp
          } else {
            if(Moment(cred.data.expires, 'X') > Moment()) {
              num += tmp
            }
          }
        }
      }
    }
    for(var sub of global.subscriptions) {
      if(products.length === 0 || products.indexOf(sub.id) !== -1) {
        var tot = 0
        if(sub.data.group && sub.data.sessions > 0 && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === global.spaceClient)) {
          tot = sub.data.sessions
          /*for(var item2 of global.sessions) {
            if(item2.data.group && item2.data.attendance === 3 && Moment().format('MM/YYYY') === Moment(item2.data.timestamp, 'X').format('MM/YYYY')) {
              tot -= 1
            }
          }*/
         tot -= sub.data.done
        }
        if(tot > 0) {
          num -= tot
        }
      }
    }
    for(var sub2 of global.subscriptions) {
      if(products.length === 0 || products.indexOf(sub2.id) !== -1) {
        if(sub2.data.group && sub2.data.sessions === 0 && (sub2.data.account === undefined || sub2.data.account === '' || sub2.data.account === global.spaceClient)) {
          num = -1
        }
      }
    }
    if(num >= 0) {
      available = false
    }
    if(global.userStripeConnect === '' || global.userStripeConnect === undefined || !global.userLimitBooking) {
      available = true
    }
    if(available) {
      this.addBookedConfirmed(item, name)
      if(type === 'recurring') {
        var clients = [item]
        for(var rec of global.recurring) {
          if(rec.id === this.state.item.data.template && rec.data.clients !== undefined) {
            clients = rec.data.clients
            clients.push(item)
          }
        }
        Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.data.template).update({
          clients: clients
        })
        for(var sess of global.sessions) {
          var add = true
          if(sess.data.max !== 0 && sess.data.max !== undefined) {
            if(sess.data.clients !== undefined) {
              if(sess.data.clients.length >= sess.data.max) {
                add = false
              }
            }
          }
          if(sess.data.template === this.state.item.data.template && Moment(sess.data.timestamp, 'X') > Moment() && add) {
            if(sess.data.clients !== undefined) {
              if(sess.data.clients.indexOf(item) === -1) {
                sess.data.clients.push(item)
              }
              var bookings = []
              if(sess.data.bookings !== undefined) {
                bookings = sess.data.bookings
              }
              bookings.push(item+"||booking||recurring||"+Moment().format('X'))
              Firebase.database().ref('/sessions/'+global.uid+'/'+sess.id).update({
                clients: sess.data.clients,
                bookings: bookings
              })
            } else {
              Firebase.database().ref('/sessions/'+global.uid+'/'+sess.id).update({
                clients: [item]
              })
            }
            this.addRecurringSchedule(sess)
          }
        }
      }
    } else {
      this.showOverlaySessions()
    }
  }


  addBookedConfirmed(item, name) {
    var tmp = []
    if(this.state.item.data.clients !== undefined) {
      tmp = this.state.item.data.clients
    }
    tmp.push(item)
    var bookings = []
    if(this.state.item.data.bookings !== undefined) {
      bookings = this.state.item.data.bookings
    }
    bookings.push(item+"||booking||member||"+Moment().format('X'))
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp,
      bookings: bookings,
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', name+' '+lang.t('message:bookedin'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(item === global.spaceClient) {
      Firebase.database().ref('activity/'+global.uid).push({
        type: 'booking',
        data: global.uidUser+','+this.state.item.id,
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
    }
    var name = lang.t('calendar:label.group')
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = lang.t('calendar:label.available')
    }
    
    Connector.sendPushNotification(global.clientToken, lang.t('message:push.booking.title'), global.userName+' '+lang.t('message:push.booking.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
    // Email reminder
    if(global.spaceEmailReminder && global.clientEmailReminder) {
      var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
      var callFunction = Firebase.functions().httpsCallable('sendReminder');
      callFunction({type: 'booked', email: global.userEmail, name: global.userBusiness, id: global.uid, session: name, time: Moment(this.state.item.data.timestamp, 'X').format('llll'), scheduled: scheduled}).then(function(result) {});
    }

    // Scheduled notifications
    var found1 = false
    var found2 = false
    for(var sitem of global.schedule) {
      if(sitem.id === this.state.item.id+'-push' && global.userToken !== '') {
        found1 = true
        var tmp1 = []
        if(sitem.data.tokens !== undefined) {
          tmp1 = sitem.data.tokens
        }
        tmp1.push(global.userToken)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp1,
        })
      }
      if(sitem.id === this.state.item.id+'-email' && global.userEmail !== '' && global.spaceEmailReminder && global.clientEmailReminder) {
        found2 = true
        var tmp2 = []
        if(sitem.data.tokens !== undefined) {
          tmp2 = sitem.data.tokens
        }
        tmp2.push(global.userEmail)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp2,
        })
      }
    }

    var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
    if(global.userToken !== '' && !found1) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-push').update({
        title: "Session Reminder",
        desc: "You are booked in for "+name+" "+Cal.getSpecialDatePush(this.state.item.data.timestamp, scheduled)+".",
        type: "",
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [global.userToken],
        message: "push",
        uid: global.uid,
        iid: this.state.item.id
      })
    }
    if(global.userEmail !== '' && !found2 && global.spaceEmailReminder && global.clientEmailReminder) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-email').update({
        title: global.userBusiness,
        desc: name,
        type: Cal.getSpecialDatePush(this.state.item.data.timestamp, scheduled),
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [global.userEmail],
        message: "sessionemail",
        uid: global.uid,
        iid: global.uid
      })
    }
  }


  addRecurringSchedule(sess) {
    // Scheduled notifications
    var found1 = false
    var found2 = false
    var name = "Class"
    if(sess.data.client !== '') {
      name = sess.data.client
    }
    for(var sitem of global.schedule) {
      if(sitem.id === sess.id+'-push' && global.userToken !== '') {
        found1 = true
        var tmp1 = []
        if(sitem.data.tokens !== undefined) {
          tmp1 = sitem.data.tokens
        }
        tmp1.push(global.userToken)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp1,
        })
      }
      if(sitem.id === sess.id+'-email' && global.userEmail !== '' && global.spaceEmailReminder && global.clientEmailReminder) {
        found2 = true
        var tmp2 = []
        if(sitem.data.tokens !== undefined) {
          tmp2 = sitem.data.tokens
        }
        tmp2.push(global.userEmail)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp2,
        })
      }
    }

    var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
    if(global.userToken !== '' && !found1) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-push').update({
        title: "Session Reminder",
        desc: "You are booked in for "+name+" "+Cal.getSpecialDatePush(this.state.item.data.timestamp, scheduled)+".",
        type: "",
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [global.userToken],
        message: "push",
        uid: global.uid,
        iid: this.state.item.id
      })
    }
    if(global.userEmail !== '' && !found2 && global.spaceEmailReminder && global.clientEmailReminder) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-email').update({
        title: global.userBusiness,
        desc: name,
        type: Cal.getSpecialDatePush(this.state.item.data.timestamp, scheduled),
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [global.userEmail],
        message: "sessionemail",
        uid: global.uid,
        iid: global.uid
      })
    }
  }


  addWaiting(item, name) {
    var tmp = []
    if(this.state.item.data.waiting !== undefined) {
      tmp = this.state.item.data.waiting
    }
    tmp.push(item)
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      waiting: tmp
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', name+' '+lang.t('message:waitinglist'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  removeBooked(item, type) {
    var tmp = this.state.item.data.clients
    var tmp2 = []
    var id2 = ''
    var wc = null
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      tmp2 = this.state.item.data.waiting
      if(this.state.item.data.waiting.length > 0) {
        tmp.push(tmp2[0])
        id2 = tmp[0]
        tmp2.splice(0, 1)
      }
    }
    var name = lang.t('message:push.bookedin.group')
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = lang.t('message:push.bookedin.available')
    }
    Connector.sendPushNotification(global.clientToken, lang.t('message:push.bookingcancelled.title'), global.userName+' '+lang.t('message:push.bookingcancelled.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
    var text2 = lang.t('message:push.bookedin.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date)
    for(var client of global.clients) {
      if(client.id === id2 && id2 !== '') {
        wc = client
        if(client.data.pushToken !== undefined && client.data.pushToken !== '') {
          Connector.sendPushNotification(client.data.pushToken, lang.t('message:push.bookedin.title'), text2, 'session', this.state.item.id)
        }
      }
    }
    var bookings = []
    if(this.state.item.data.bookings !== undefined) {
      bookings = this.state.item.data.bookings
    }
    bookings.push(item+"||cancellation||member||"+Moment().format('X'))
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp,
      waiting: tmp2,
      bookings: bookings,
    })
    if(item === global.spaceClient) {
      Firebase.database().ref('activity/'+global.uid).push({
        type: 'bookingcancelled',
        data: global.uidUser+','+this.state.item.id,
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
    }
    if(type === 'recurring') {
      for(var rec of global.recurring) {
        if(rec.id === this.state.item.data.template) {
          var tmpc = rec.data.clients
          for(var i1=0; i1<tmpc.length; i1++) {
            if(tmpc[i1] === item) {
              tmpc.splice(i1, 1)
            }
          }
          Firebase.database().ref('/recurring/'+global.uid+'/'+rec.id).update({
            clients: tmpc,
          })
        }
      }
      for(var sess of global.sessions) {
        if(sess.data.template === this.state.item.data.template && sess.data.clients !== undefined) {
          var tmps = sess.data.clients
          for(var i2=0; i2<tmps.length; i2++) {
            if(tmps[i2] === item) {
              tmps.splice(i2, 1)
            }
          }
          bookings = []
          if(sess.data.bookings !== undefined) {
            bookings = sess.data.bookings
          }
          bookings.push(item+"||cancellation||member||"+Moment().format('X'))
          if(wc !== null) {
            bookings.push(item+"||booking||waiting||"+Moment().format('X'))
          }
          Firebase.database().ref('/sessions/'+global.uid+'/'+sess.id).update({
            clients: tmps,
            bookings: bookings,
          })
          // Update schedule
          for(var sitem1 of global.schedule) {
            if(sitem1.id === sess.id+'-push') {
              var tmp11 = []
              if(sitem1.data.tokens !== undefined) {
                tmp11 = sitem1.data.tokens
              }
              if(wc !== null) {
                if(wc.data.pushToken !== '' && wc.data.pushToken !== undefined) {
                  tmp11.push(wc.data.pushToken)
                }
              }
              if(tmp11.length > 0) {
                for(var i11=0; i11<tmp11.length; i11++) {
                  if(tmp11[i11] === global.userToken) {
                    tmp11.splice(i11, 1)
                  }
                }
                Firebase.database().ref('/schedule/'+sitem1.id).update({
                  tokens: tmp11
                })
              }
            }
            if(sitem1.id === sess.id+'-email') {
              var tmp21 = []
              if(sitem1.data.tokens !== undefined) {
                tmp21 = sitem1.data.tokens
              }
              if(wc !== null) {
                if(wc.data.email !== '' && wc.data.email !== undefined) {
                  tmp21.push(wc.data.pushToken)
                }
              }
              if(tmp2.length > 0) {
                for(var i21=0; i21<tmp21.length; i21++) {
                  if(tmp21[i21] === global.userEmail) {
                    tmp21.splice(i21, 1)
                  }
                }
                Firebase.database().ref('/schedule/'+sitem1.id).update({
                  tokens: tmp21
                })
              }
            }
          }
        }
      }
    }
    if(global.spaceEmailReminder && global.clientEmailReminder) {
      var name = lang.t('calendar:label.group')
      if(this.state.item.data.client !== '') {
        name = this.state.item.data.client
      }
      if(this.state.item.data.availability) {
        name = lang.t('calendar:label.available')
      }
      var callFunction = Firebase.functions().httpsCallable('sendReminder');
      callFunction({type: 'canceled', email: global.userEmail, name: global.userName, id: global.uid, session: name, time: Moment(this.state.item.data.timestamp, 'X').format('llll')}).then(function(result) {});
    }
    EventEmitter.dispatch('showMessage', lang.t('message:push.bookingcancelled.title'));

    // Update schedule
    for(var sitem of global.schedule) {
      if(sitem.id === this.state.item.id+'-push') {
        var tmp1 = []
        if(sitem.data.tokens !== undefined) {
          tmp1 = sitem.data.tokens
        }
        if(tmp1.length > 0) {
          for(var i1=0; i1<tmp1.length; i1++) {
            if(tmp1[i1] === global.userToken) {
              tmp1.splice(i1, 1)
            }
          }
          Firebase.database().ref('/schedule/'+sitem.id).update({
            tokens: tmp1
          })
        }
      }
      if(sitem.id === this.state.item.id+'-email') {
        var tmp2 = []
        if(sitem.data.tokens !== undefined) {
          tmp2 = sitem.data.tokens
        }
        if(tmp2.length > 0) {
          for(var i2=0; i2<tmp2.length; i2++) {
            if(tmp2[i2] === global.userEmail) {
              tmp2.splice(i2, 1)
            }
          }
          Firebase.database().ref('/schedule/'+sitem.id).update({
            tokens: tmp2
          })
        }
      }
    }
  }


  removeWaiting(item) {
    var tmp = this.state.item.data.waiting
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      waiting: tmp
    })
    EventEmitter.dispatch('showMessage', lang.t('message:clientremoved'));
  }



  // Unavailable overlay ------------------------------------------------------------



  showOverlaySessions() {
    this.setState({
      showOverlaySessions: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenSessions: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlaySessions() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenSessions: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlaySessions: false});
    }, 500);
  }


  renderOverlaySessions() {
    if(this.state.showOverlaySessions) {
      return (
        <div className={'overlay '+this.state.hiddenSessions}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:nosessions.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlaySessions()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:nosessions.title')}</p>
            <button className="btn primary" onClick={() => this.hideOverlaySessions()}>{lang.t('common:button.gotit')}</button>
          </div>
        </div>
      )
    }
  }



  //Recurring overlay ------------------------------------------------------------



  showOverlayRecurring() {
    this.setState({
      showOverlayRecurring: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenRecurring: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayRecurring() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenRecurring: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayRecurring: false});
    }, 500);
  }


  renderOverlayRecurring() {
    if(this.state.showOverlayRecurring) {
      return (
        <div className={'overlay '+this.state.hiddenRecurring}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:recurring.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayRecurring()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:recurring.text')}</p>
            <button className="btn primary mb-20" onClick={() => this.addMultiple('single')}>{lang.t('modal:recurring.button')}</button>
            <button className="btn secondary" onClick={() => this.addMultiple('recurring')}>{lang.t('modal:recurring.button2')}</button>
          </div>
        </div>
      )
    }
  }


  addMultiple(type) {
    this.hideOverlayRecurring()
    if(type === 'single') {
      this.addBooked(this.state.current, this.state.currentn, 'single')
    } else {
      this.addBooked(this.state.current, this.state.currentn, 'recurring')
    }
  }



  //Recurring overlay ------------------------------------------------------------



  showOverlayRecurringCancel() {
    this.setState({
      showOverlayRecurringCancel: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenRecurringCancel: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayRecurringCancel() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenRecurringCancel: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayRecurringCancel: false});
    }, 500);
  }


  renderOverlayRecurringCancel() {
    if(this.state.showOverlayRecurringCancel) {
      return (
        <div className={'overlay '+this.state.hiddenRecurringCancel}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:recurringcancel.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayRecurringCancel()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:recurringcancel.text')}</p>
            <button className="btn primary mb-20" onClick={() => this.removeMultiple('single')}>{lang.t('modal:recurringcancel.button')}</button>
            <button className="btn secondary" onClick={() => this.removeMultiple('recurring')}>{lang.t('modal:recurringcancel.button2')}</button>
          </div>
        </div>
      )
    }
  }


  removeMultiple(type) {
    this.hideOverlayRecurringCancel()
    if(type === 'single') {
      this.removeBooked(this.state.current, 'single')
    } else {
      this.removeBooked(this.state.current, 'recurring')
    }
  }



  // Family overlay ------------------------------------------------------------



  showOverlay() {
    this.setState({
      showOverlay: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlay() {
    if(this.state.showOverlay) {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: 'hidden',
        margin: -height/2+100
      })
      setTimeout(() => {
        this.setState({showOverlay: false});
      }, 500);
    }
    
  }


  getStatus(item) {
    var label = lang.t('calendar:label.notbookedin')
    if(this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.indexOf(item) !== -1) {
        label = lang.t('calendar:label.bookedin')
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.indexOf(item) !== -1) {
        label = lang.t('calendar:label.onwaitinglist')
      }
    }
    return label
  }


  renderButton(item) {
    var name = 'You'
    if(item !== global.spaceClient) {
      name = this.getClientName(item)
    }
    var btn = <button className="btn tertiary small" onClick={() => this.checkRecurring(item, name)}>{lang.t('calendar:button.bookin')}</button>
    if(this.state.item.data.max !== undefined && this.state.item.data.clients !== undefined) {
      if(this.state.item.data.max > 0 && this.state.item.data.clients.length >= this.state.item.data.max) {
        btn = <button className="btn tertiary small" onClick={() => this.addWaiting(item, name)}>{lang.t('calendar:button.addwaiting')}</button>
      }
      
    }
    if(this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.indexOf(item) !== -1) {
        if(Moment() < Moment(this.state.item.data.locked, 'X')) {
          btn = <button className="btn tertiary small" onClick={() => this.checkRecurringCancel(item)}>{lang.t('calendar:button.cancelbooking')}</button>
        } else {
          btn = <span></span>
        }
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.indexOf(item) !== -1) {
        if(Moment() < Moment(this.state.item.data.locked, 'X')) {
          btn = <button className="btn tertiary small" onClick={() => this.removeWaiting(item)}>{lang.t('calendar:button.removewaiting')}</button>
        } else {
          btn = <span></span>
        }
      }
    }
    return btn
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      var list = global.userLinked
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:booking.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-20">{lang.t('modal:booking.text')}</p>
            <div className="client small mb-10">
              <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
              <div className="text">
                <h4>{lang.t('common:label.yourself')}</h4>
                <p>{this.getStatus(global.spaceClient)}</p>
                {this.renderButton(global.spaceClient)}
              </div>
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <div className="client small mb-10">
                {HelperClients.getImage(this.getClient(item))}
                <div className="text">
                  <h4>{this.getClientName(item)}</h4>
                  <p>{this.getStatus(item)}</p>
                  {this.renderButton(item)}
                </div>
                <div className="clear"></div>
              </div>
            ))}
            <div className="sv-10"></div>
          </div>
        </div>
      )
    }
  }



  // Delete overlay ------------------------------------------------------------



  showOverlayDelete() {
    this.setState({showOverlayDelete: true})
    setTimeout(() => {
      this.setState({
        hiddenDelete: '',
        deleted: true
      });
    }, 100);
  }


  hideOverlayDelete() {
    this.setState({hiddenDelete: 'hidden'})
    setTimeout(() => {
      this.setState({
        showOverlayDelete: false,
        deleted: false
      });
    }, 500);
  }


  renderOverlayDelete() {
    if(this.state.showOverlayDelete) {
      return (
        <div className={'overlay '+this.state.hiddenDelete}>
          <div className="box text">
            <h2 className="mb-20 lft">{lang.t('modal:deletesession.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDelete()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:deletesession.text')}</p>
            <button className="btn primary" onClick={() => this.deleteSession()}>{lang.t('calendar:button.deletesession')}</button>
          </div>
        </div>
      )
    }
  }


  deleteSession() {
    this.hideOverlayDelete()
    Firebase.database().ref('/sessions/'+global.uidUser+'/'+this.state.item.id).remove()
    global.message = lang.t('message:sessiondeleted')
    this.props.history.push('/'+global.uid+'/training/sessions')
  }



  // Program stuff ------------------------------------------------------------



  getBlockType(item) {
    var label = ''
    var s = lang.t('training:prog.round')
    if(item.rounds > 1) {
      s = lang.t('training:prog.rounds')
    }
    if(item.type === 0) {
      label = ' - '+Cal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+s
      if(item.emom) {
        label = ' - '+item.rounds+' '+s+' '+lang.t('training:prog.of')+' '+Cal.getDurationMin(parseInt(item.exWork[0]))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('training:prog.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('training:prog.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' '+lang.t('training:prog.of')+' '+Cal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('training:prog.worknorest')
        } else {
          label += ' '+lang.t('training:prog.of')+' '+Cal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('training:prog.work')+' & '+Cal.getDurationMin(parseInt(item.exRest[0]))+' '+lang.t('training:prog.rest')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.getSetsNameStatic(item)+'s'
      if(item.rounds === 1) {
        label = ' - 1 '+this.getSetsNameStatic(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+Cal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('training:prog.rounds')+' - '+lang.t('training:prog.cap')+': '+Cal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  getBlockTitle(item) {
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  getSetsNameStatic(item) {
    var label = lang.t('training:prog.set')
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('training:prog.superset')
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('training:prog.dropset')
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('training:prog.dropset')
      } else {
        label = lang.t('training:prog.superset')
      }
    }
    return label
  }


  showModalBlock(item) {
    for(var bl of this.state.item.program[0].blocks) {
      if(bl.id === item) {
        this.setState({
          block: bl,
          showModalBlock: true
        })
      }
    }
  }


  hideModals() {
    this.setState({
      showModalBlock: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderStatus() {
    var label = lang.t('calendar:attendance.bookedin')
    var date = Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X')
    if(this.state.item.data.clients !== undefined && this.state.item.data.group) {
      if(this.state.item.data.clients.indexOf(global.spaceClient) !== -1) {
        if(parseInt(Moment().format('X')) > parseInt(date)) {
          label = lang.t('calendar:attendance.attended')
        }
        return (
          <div className="infobox small green mb-20">
            <p>{label}</p>
          </div>
        )
      } else {
        if(this.state.item.data.waiting !== undefined) {
          if(this.state.item.data.waiting.indexOf(global.spaceClient) !== -1 && parseInt(Moment().format('X')) < parseInt(date)) {
            return (
              <div className="infobox small yellow mb-20">
                <p>{lang.t('calendar:attendance.waiting')}</p>
              </div>
            )
          }
        }
      }
    } else if(!this.state.item.data.group && this.state.item.data.attendance === 3) {
      return (
        <div className="infobox small green mb-20">
          <p>{lang.t('calendar:attendance.attended2')}</p>
        </div>
      )
    }
  }


  renderRating() {
    if(global.userComments && this.state.item.data.attendance === 3) {
      return (
        <div className="mb-50">
          <h3 className="mb-20">{lang.t('header:cal.rating')}</h3>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(1)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(2)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(3)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(4)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(5)}}></div>
          </div>
          <p className="star-rate">{this.getRating()}</p>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderCommentsButton() {
    if(this.state.editComment === '' && !global.userRestricted) {
      if(this.state.writeComment) {
        return (
          <div>
            <h4>{lang.t('calendar:button.writecomment')}</h4>
            <div className="sv-20"></div>
            <textarea value={this.state.comment} onChange={this.onChangeComment}></textarea>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.sendComment('')}>{lang.t('common:button.send')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary close width-12" onClick={() => this.setState({writeComment: false})}>{lang.t('common:button.cancel')}</button>
            <div className="sv-20"></div>
          </div>
        )
      } else {
        return (
          <button className="btn tertiary add width-12" onClick={() => this.setState({writeComment: true, comment: ''})}>{lang.t('calendar:button.writecomment')}</button>
        )
      }
    }
  }


  renderCommentEdit(item) {
    if(item.data.sender === global.uidUser && !this.state.writeComment && !global.userRestricted) {
      if(this.state.editComment === '') {
        return (
          <button className="btn tertiary small edit" onClick={() => this.setState({editComment: item.id, comment: item.data.text})}>{lang.t('common:button.edit')}</button>
        )
      } else if(this.state.editComment === item.id) {
        return (
          <div>
            <textarea value={this.state.comment} onChange={this.onChangeComment}></textarea>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.sendComment(item.id)}>{lang.t('calendar:button.updatecomment')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary close width-12" onClick={() => this.setState({editComment: ''})}>{lang.t('common:button.cancel')}</button>
            <div className="sv-20"></div>
          </div>
        )
      }
    }
  }


  renderComments() {
    var booked = false
    if(this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.indexOf(global.spaceClient) !== -1) {
        booked = true
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.indexOf(global.spaceClient) !== -1) {
        booked = true
      }
    }
    if(!this.state.item.data.group) {
      booked = true
    }
    if(booked) {
      if(global.userComments) {
        if(this.state.item.comments === undefined || this.state.item.comments.length === 0) {
          return (
            <div>
              <div className="empty small pt-20">
                {HelperEmpty.renderImage('comments')}
                <h4>{lang.t('calendar:comments.nocomments')}</h4>
              </div>
              <div className="sv-40"></div>
              {this.renderCommentsButton()}
            </div>
          )
        } else {
          var list = this.state.item.comments;
          list.sort((a,b) => Moment(b.data.date, 'X') - Moment(a.data.date, 'X'));
          return (
            <div>
              {list.map(item => (
                <div className="list bare" key={item.id}>
                  {this.getCommentImage(item.data.sender)}
                  <div className="main">
                  {this.renderCommentEdit(item)}
                    <h4>{this.getCommenter(item.data.sender)}</h4>
                    <h6 style={{marginBottom: 5}}>{Cal.getPushDate(Moment(item.data.date, 'X'))}</h6>
                    <p>{item.data.text}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
              <div className="sv-20"></div>
              {this.renderCommentsButton()}
            </div>
          )
        }
      } else {
        return (
          <div className="infobox">
            <p>{lang.t('calendar:comments.disabled2')}</p>
          </div>
        )
      }
    } else {
      return (
        <div className="infobox">
          <p>{lang.t('calendar:comments.disabled')}</p>
        </div>
      )
    }
  }


  getAttendanceClass() {
    var label = 'attendance border padding'
    if(this.state.item.data.clients === undefined || this.state.item.data.clients.length === 0) {
      label = 'attendance border'
    }
    return label
  }


  renderAttendeesList(type) {
    var label = lang.t('calendar:label.nobookings')
    if(this.state.item.data.availability) {
      label = lang.t('calendar:label.nobookings')
    }
    if(this.state.item.data.unlocked !== undefined && this.state.item.data.unlocked !== '01/01/1900 00:00') {
      if(Moment() < Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm')) {
        label = lang.t('calendar:label.bookingnotopen')
      }
    }
    if(type === 'past') {
      label = (this.state.avail ? lang.t('calendar:label.selectattendee') : lang.t('calendar:label.selectattendees'))
    }
    if(global.userShowBooked) {
      if(this.state.item.data.clients === undefined || this.state.item.data.clients.length === 0) {
        return (
          <p>{label}</p>
        )
      } else {
        return (
          <div>
            {this.state.item.data.clients.map(item => (
              <div className="client small" key={item}>
                {HelperClients.getImage(this.getClient(item))}
                <p>{item === global.spaceClient ? lang.t('common:label.you') : this.getClientName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderWaiting() {
    if(this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.length > 0) {
        return (
          <div className="mb-20">
            <label>{lang.t('calendar:label.waiting')}</label>
            {this.state.item.data.waiting.map(item => (
              <div className="client small" key={item}>
                {HelperClients.getImage(this.getClient(item))}
                <p>{item === global.spaceClient ? lang.t('common:label.you') : this.getClientName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderAttendance() {
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date && global.userShowBooked && this.state.item.data.group) {
      return (
        <div className="mb-20">
          <label>{now > date2 ? lang.t('calendar:label.attended') : lang.t('calendar:label.bookedin')} {this.state.max > 0 && !this.state.avail ? '('+this.state.max+' '+lang.t('common:label.max')+')' : ''}</label>
          {this.renderAttendeesList('past')}
        </div>
      )
    } else if(now < date && global.userShowBooked && this.state.item.data.group) {
      if(this.state.item.data.availability) {
        return (
          <div className="mb-20">
            <label>Client booked in</label>
            {this.renderAttendeesList('future')}
          </div>
        )
      } else {
        return (
          <div className="mb-20">
            <label className="lft">{this.state.item.data.attendance === 2 ? lang.t('calendar:label.bookedin') : lang.t('calendar:label.attending')} {this.state.max > 0 ? '('+this.state.max+' '+lang.t('common:label.max')+')' : ''}</label>
            <div className="clear"></div>
            {this.renderAttendeesList('future')}
          </div>
        )
      }
    }
  }


  checkRecurring(client, name) {
    if(global.userLinked.length > 0) {
      this.hideOverlay()
    }
    this.setState({
      current: client,
      currentn: name,
    })
    if(this.state.item.data.template === undefined || this.state.item.data.template === '') {
      this.addBooked(client, name, 'single')
    } else {
      var show = false
      for(var rec of global.recurring) {
        if(rec.id === this.state.item.data.template) {
          if(rec.data.clients === undefined) {
            show = true
          } else {
            if(rec.data.clients.indexOf(client) === -1) {
              show = true
            }
          }
          if(rec.data.max !== undefined && rec.data.max !== 0 && rec.data.clients !== undefined) {
            if(rec.data.clients.length > rec.data.max) {
              show = false
            }
          }
        }
      }
      if(!global.spaceAllowRecurring) {
        show = false
      }
      if(show) {
        this.showOverlayRecurring()
      } else {
        this.addBooked(client, name, 'single')
      }
    }
  }


  checkRecurringCancel(client) {
    if(global.userLinked.length > 0) {
      this.hideOverlay()
    }
    this.setState({
      current: client,
    })
    if(this.state.item.data.template === undefined || this.state.item.data.template === '') {
      this.removeBooked(client, 'single')
    } else {
      var show = false
      for(var rec of global.recurring) {
        if(rec.id === this.state.item.data.template) {
          if(rec.data.clients !== undefined) {
            if(rec.data.clients.indexOf(client) !== -1) {
              show = true
            }
          }
        }
      }
      if(show) {
        this.showOverlayRecurringCancel()
      } else {
        this.removeBooked(client, 'single')
      }
    }
  }


  renderBookButton() {
    var max = this.state.item.data.max
    if(max === 0) {
      max = 99999
    }
    if(!global.userRestricted) {
      if(Moment() > Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm') && Moment() < Moment(this.state.item.data.locked, 'X')) {
        if(global.userLinked.length === 0) {
          if(this.state.item.data.clients === undefined) {
            return (
              <button className="btn primary" onClick={() => this.checkRecurring(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.bookin')}</button>
            )
          } else {
            if(this.state.item.data.clients.indexOf(global.spaceClient) !== -1) {
              return (
                <button className="btn secondary" onClick={() => this.checkRecurringCancel(global.spaceClient)}>{lang.t('calendar:button.cancelbooking')}</button>
              )
            } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length < max) {
              return (
                <button className="btn primary" onClick={() => this.checkRecurring(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.bookin')}</button>
              )
            } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length >= max) {
              var waiting = false
              if(this.state.item.data.waiting !== undefined) {
                if(this.state.item.data.waiting.indexOf(global.spaceClient) !== -1) {
                  waiting = true
                }
              }
              if(waiting) {
                return (
                  <button className="btn secondary" onClick={() => this.removeWaiting(global.spaceClient)}>{lang.t('calendar:button.leavewaiting')}</button>
                )
              } else {
                return (
                  <div className="info">
                    <p>{lang.t('calendar:waiting.text')}</p>
                    <div className="sv-30"></div>
                    <button className="btn primary" onClick={() => this.addWaiting(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.waiting')}</button>
                  </div>
                )
              }
            }
          }
        } else {
          return (
            <button className="btn primary" onClick={() => this.showOverlay()}>{lang.t('calendar:button.manage')}</button>
          )
        }
      } else if(Moment() < Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm')) {
        return (
          <div className="infobox large primary">
            <p>{lang.t('calendar:booking.open')} {Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM LT')}</p>
          </div>
        )
      } else if(Moment() > Moment(this.state.item.data.locked, 'X')) {
        if(global.userAllowBooking) {
          return (
            <div>
              <div className="infobox large yellow">
                <p>{lang.t('calendar:booking.closed')}</p>
              </div>
              {this.renderBookOverride()}
            </div>
          )
        } else {
          return (
            <div className="infobox large yellow">
              <p>{lang.t('calendar:booking.closed')}</p>
            </div>
          )
        }
      }
    }
  }


  renderBookOverride() {
    var max = this.state.item.data.max
    if(max === 0) {
      max = 99999
    }
    if(!global.userRestricted) {
      if(global.userLinked.length === 0) {
        if(this.state.item.data.clients === undefined) {
          return (
            <button className="btn primary" onClick={() => this.addBooked(global.spaceClient, lang.t('common:label.you'), 'single')}>{lang.t('calendar:button.bookin')}</button>
          )
        } else {
          if(this.state.item.data.clients.indexOf(global.spaceClient) !== -1) {
            // Can't cancel
          } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length < max) {
            return (
              <button className="btn primary" onClick={() => this.addBooked(global.spaceClient, lang.t('common:label.you'), 'single')}>{lang.t('calendar:button.bookin')}</button>
            )
          } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length >= max) {
            var waiting = false
            if(this.state.item.data.waiting !== undefined) {
              if(this.state.item.data.waiting.indexOf(global.spaceClient) !== -1) {
                waiting = true
              }
            }
            if(waiting) {
              // Can't leave waiting list
            } else {
              return (
                <div className="info">
                  <p style={{fontSize: 12}}>{lang.t('calendar:waiting.text')}</p>
                  <div className="space-30"></div>
                  <button className="btn primary" onClick={() => this.addWaiting(global.spaceClient, 'You')}>{lang.t('calendar:button.waiting')}</button>
                </div>
              )
            }
          }
        }
      } else {
        return (
          <button className="btn primary" onClick={() => this.showOverlay()}>{lang.t('calendar:button.manage')}</button>
        )
      }
    }
  }


  renderMainButton() {
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(this.state.item.data.group) {
      if(date2 > now) {
        return (
          <div>
            {this.renderBookButton()}
          </div>
        )
      } else if(now > date2 && now < date && this.state.item.data.link !== '') {
        return (
          <a className="btn primar mb-20" href={this.state.item.data.link} target="_blank" rel="noopener noreferrer">{lang.t('calendar:button.joinsession')}</a>
        )
      } else if(this.state.item.data.attendance === 3 && now > date2 && this.state.item.program.length > 0) {
        return (
          <Link to={'/'+global.uid+'/results/'+this.state.item.id} className="btn secondary mb-20">{lang.t('calendar:button.updateresults')}</Link>
        )
      }
    } else {
      if(now > date2 && now < date && this.state.item.data.link !== '') {
        return (
          <a className="btn primary mb-20" href={this.state.item.data.link} target="_blank" rel="noopener noreferrer">{lang.t('calendar:button.joinsession')}</a>
        )
      } else {
        if(this.state.item.data.uid === global.uidUser && this.state.item.data.attendance === 3 && this.state.item.program.length > 0) {
          return (
            <div>
              <Link to={'/'+global.uid+'/results/'+this.state.item.id} className="btn secondary mb-20">{lang.t('calendar:button.updateresults')}</Link>
              <button className="btn tertiary width-12 close" onClick={() => this.showOverlayDelete()}>{lang.t('calendar:button.deletesession')}</button>
            </div>
          )
        } else if(this.state.item.data.uid !== global.uidUser && this.state.item.data.attendance === 3 && this.state.item.program.length > 0) {
          return (
            <Link to={'/'+global.uid+'/results/'+this.state.item.id} className="btn secondary mb-20">{lang.t('calendar:button.updateresults')}</Link>
          )
        }
      }
    }
  }


  renderProgramButton() {
    if(this.state.item.data.attendance === 2) {
      return (
        <Link to={'/'+global.uid+'/session/'+this.state.item.id+'/program/'+this.state.item.program[0].id} className="btn tertiary small" style={{width: 'auto'}}>{lang.t('calendar:button.viewprogram')}</Link>
      )
    }
  }


  renderProgram() {
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').format('X'))
    var now = parseInt(Moment().format('X'))
    if(this.state.item.program.length === 0 || !this.state.item.data.preview) {
      if(now < date) {
        return (
          <div className="image empty large mt-30" style={{transform: 'scale(.8)'}}>
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('programs')}
            </div>
            <h3 className="mb-20 mt-20">{this.state.item.data.group ? lang.t('calendar:preview.title2') : lang.t('calendar:preview.title')}</h3>
            <p>{this.state.item.data.group ? lang.t('calendar:preview.text2') : lang.t('calendar:preview.text')}</p>
          </div>
        )
      } else {
        return (
          <div className="image empty large mt-30" style={{transform: 'scale(.8)'}}>
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('programs')}
            </div>
            <h3 className="mb-20 mt-20">{this.state.item.data.group ? lang.t('calendar:program.title2') : lang.t('calendar:program.title')}</h3>
            <p>{this.state.item.data.group ? lang.t('calendar:program.text2') : lang.t('calendar:program.text')}</p>
          </div>
        )
      }
      
    } else {
      var list = this.state.item.program[0].blocks
      return (
        <div className="session-program">
          <div className="sv-30"></div>
          <h5 className="mb-10">{this.state.item.program[0].data.name} ({this.state.item.program[0].data.time} min)</h5>
          {list.map(item => (
            <div className="box highlight list simple mb-10" key={item.id} onClick={() => this.showModalBlock(item.id)}>
              <p className="lft"><strong>{this.getBlockTitle(item)}</strong><br/>{lang.t(global.proTypes[item.data.type])} {this.getBlockType(item.data)}</p>
              <div className="clear"></div>
            </div>
          ))}
          <div className="clear sv-20"></div>
        </div>
      )
    }
  }


  renderVirtual() {
    if(this.state.item.data.link !== undefined && this.state.item.data.link !== '') {
      return <span className="pill primary" style={{marginLeft: 5, position: 'relative', top: '-2px'}}>{lang.t('calendar:label.virtual')}</span>
    }
  }


  renderLocation() {
    if(this.state.item.data.locationName !== undefined) {
      return (this.state.item.data.locationName === '' ? '' : ' - '+this.state.item.data.locationName)
    }
  }


  renderContent() {
    if(this.state.item === null) {
      return (
        <div></div>
      )
    } else {
      return (
        <div>
          <div className="col-6 mt-20">
            <div className="box">
              {this.renderStatus()}
              <div className="box-session" style={{height: 'auto'}}>
                <h4 style={{marginBottom: 5}}>{this.getSessionTime()}{this.renderVirtual()}</h4>
                <h3 className="mb-20">{Cal.getDuration(this.state.item.data.duration)} {this.renderLocation()}</h3>
                <div className="clear"></div>
                {this.renderAttendance()}
                {this.renderWaiting()}
                <div className="s -20"></div>
                {this.renderProgram()}
                <div className="sv-20"></div>
                {this.renderMainButton()}
              </div>
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
            {this.renderRating()}
            <h3 className="mb-10">{lang.t('header:cal.comments')}</h3>
            {this.renderComments()}
          </div>
          <div className="sv-40 clear"></div>
        </div>
      )
    }
  }


  renderBase() {
    if(this.state.origin === 'training') {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            {this.renderContent()}
          </div>
          <div className="header">
            <div className="content">
              <Link to={'/'+global.uid+'/training/sessions'} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{this.getSessionName()}</h2>
            </div>
          </div>
          <Navigation active='training' />
          {this.renderOverlay()}
          {this.renderOverlayDelete()}
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            {this.renderContent()}
          </div>
          <div className="header">
            <div className="content">
              <Link to={'/'+global.uid+'/calendar'} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{this.getSessionName()}</h2>
            </div>
          </div>
          <Navigation active='calendar' />
          {this.renderOverlay()}
          {this.renderOverlayDelete()}
          {this.renderOverlaySessions()}
          {this.renderOverlayRecurring()}
          {this.renderOverlayRecurringCancel()}
          <ModalBlock show={this.state.showModalBlock} block={this.state.block} onHide={() => this.hideModals()}/>
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','common','calendar','modal','training','message'])(withRouter(withAuthorizationMember(condition)(MemberSessionPage)));