import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import HelperClients from '../../helper/Clients';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberEventPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      done: false,
      num: global.num,
      writeComment: false,
      comment: '',
      editComment: '',
      showOverlay: false,
      hidden: 'hidden',
      margin: 0,
      origin: 'calendar',
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var sid = arr[arr.length-1]
    if(id !== 'session' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.event')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true,
      id: sid
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('eventsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.event')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var arr = this.props.location.pathname.split('/')
    var sid = arr[arr.length-1]
    var item = null
    if(sid !== 'session' && sid !== 'member') {
      for(var ev of global.events) {
        if(ev.id === sid) {
          item = ev
        }
      }
    }
    this.setState({
      forms: global.forms,
      item: item,
      id: sid
    })
  }


  getEventTime() {
    var label = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM - LT')
    var tdy = Moment().format('DD/MM/YYYY')
    var tmr = Moment().add(1, 'days').format('DD/MM/YYYY')
    var dt = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    if(dt === tdy) {
      label = lang.t('calendar.label.today2')+' '+Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(dt === tmr) {
      label = lang.t('calendar.label.tomorrow2')+' '+Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    return client
  }


  getClientName(id) {
    var label = lang.t('common:label.member')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }



  // Interactive booking ------------------------------------------------------------



  addBooked(item, name) {
    if(global.userLinked.length > 0) {
      this.hideOverlay()
    }
    this.addBookedConfirmed(item, name)
  }


  addBookedConfirmed(item, name) {
    var tmp = []
    if(this.state.item.data.clients !== undefined) {
      tmp = this.state.item.data.clients
    }
    tmp.push(item)
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', name+' '+lang.t('message:bookedin'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(item === global.spaceClient) {
      Firebase.database().ref('activity/'+global.uid).push({
        type: 'bookingevent',
        data: global.uidUser+','+this.state.item.id,
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
    }

    Connector.sendPushNotification(global.clientToken, lang.t('message:push.booking.title'), global.userName+' '+lang.t('message:push.booking.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
    // Email reminder
    if(global.spaceEmailReminder && global.clientEmailReminder) {
      var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
      var callFunction = Firebase.functions().httpsCallable('sendReminder');
      callFunction({type: 'booked', email: global.userEmail, name: global.userBusiness, id: global.uid, session: this.state.item.data.title, time: Moment(this.state.item.data.timestamp, 'X').format('llll'), scheduled: scheduled}).then(function(result) {});
    }

    // Scheduled notifications
    var found1 = false
    var found2 = false
    for(var sitem of global.schedule) {
      if(sitem.id === this.state.item.id+'-push' && global.userToken !== '') {
        found1 = true
        var tmp1 = []
        if(sitem.data.tokens !== undefined) {
          tmp1 = sitem.data.tokens
        }
        tmp1.push(global.userToken)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp1,
        })
      }
      if(sitem.id === this.state.item.id+'-email' && global.userEmail !== '' && global.spaceEmailReminder && global.clientEmailReminder) {
        found2 = true
        var tmp2 = []
        if(sitem.data.tokens !== undefined) {
          tmp2 = sitem.data.tokens
        }
        tmp2.push(global.userEmail)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp2,
        })
      }
    }

    var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
    if(global.userToken !== '' && !found1) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-push').update({
        title: "Session Reminder",
        desc: "You are booked in for "+this.state.item.data.title+" "+Cal.getSpecialDatePush(this.state.item.data.timestamp, scheduled)+".",
        type: "",
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [global.userToken],
        message: "push",
        uid: global.uid,
        iid: this.state.item.id
      })
    }
    if(global.userEmail !== '' && !found2 && global.spaceEmailReminder && global.clientEmailReminder) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-email').update({
        title: global.userBusiness,
        desc: this.state.item.data.title,
        type: Cal.getSpecialDatePush(this.state.item.data.timestamp, scheduled),
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [global.userEmail],
        message: "sessionemail",
        uid: global.uid,
        iid: global.uid
      })
    }
  }


  addWaiting(item, name) {
    var tmp = []
    if(this.state.item.data.waiting !== undefined) {
      tmp = this.state.item.data.waiting
    }
    tmp.push(item)
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      waiting: tmp
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', name+' '+lang.t('message:waitinglist'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  removeBooked(item) {
    var tmp = this.state.item.data.clients
    var tmp2 = []
    var id2 = ''
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      tmp2 = this.state.item.data.waiting
      if(this.state.item.data.waiting.length > 0) {
        tmp.push(tmp2[0])
        id2 = tmp[0]
        tmp2.splice(0, 1)
      }
    }
    var name = this.state.item.data.title
    var text2 = lang.t('message:push.bookedin.text')+' '+name+' '+Cal.getPushDate(this.state.item.data.date)
    for(var client of global.clients) {
      if(client.id === id2 && id2 !== '' && client.data.pushToken !== undefined && client.data.pushToken !== '') {
        Connector.sendPushNotification(client.data.pushToken, global.userName, text2, 'event', this.state.item.id)
      }
    }
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp,
      waiting: tmp2
    })
    if(item === global.spaceClient) {
      Firebase.database().ref('activity/'+global.uid).push({
        type: 'bookingeventcancelled',
        data: global.uidUser+','+this.state.item.id,
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
    }

    // Update schedule
    for(var sitem of global.schedule) {
      if(sitem.id === this.state.item.id+'-push') {
        var tmp1 = []
        if(sitem.data.tokens !== undefined) {
          tmp1 = sitem.data.tokens
        }
        if(tmp1.length > 0) {
          for(var i1=0; i1<tmp1.length; i1++) {
            if(tmp1[i1] === global.userToken) {
              tmp1.splice(i1, 1)
            }
          }
          Firebase.database().ref('/schedule/'+sitem.id).update({
            tokens: tmp1
          })
        }
      }
      if(sitem.id === this.state.item.id+'-email') {
        var tmp2 = []
        if(sitem.data.tokens !== undefined) {
          tmp2 = sitem.data.tokens
        }
        if(tmp2.length > 0) {
          for(var i2=0; i2<tmp2.length; i2++) {
            if(tmp2[i2] === global.userEmail) {
              tmp2.splice(i2, 1)
            }
          }
          Firebase.database().ref('/schedule/'+sitem.id).update({
            tokens: tmp2
          })
        }
      }
    }
    
    EventEmitter.dispatch('showMessage', lang.t('message:bookingcancelled'));
  }


  removeWaiting(item) {
    var tmp = this.state.item.data.waiting
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      waiting: tmp
    })
    EventEmitter.dispatch('showMessage', lang.t('message:clientremoved'));
  }



  // Family overlay ------------------------------------------------------------



  showOverlay() {
    this.setState({
      showOverlay: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlay() {
    if(this.state.showOverlay) {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: 'hidden',
        margin: -height/2+100
      })
      setTimeout(() => {
        this.setState({showOverlay: false});
      }, 500);
    }
    
  }


  getStatus(item) {
    var label = lang.t('calendar:label.notbookedin')
    if(this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.indexOf(item) !== -1) {
        label = lang.t('calendar:label.bookedin')
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.indexOf(item) !== -1) {
        label = lang.t('calendar:label.onwaitinglist')
      }
    }
    return label
  }


  renderButton(item) {
    var name = lang.t('common:label.you')
    if(item !== global.spaceClient) {
      name = this.getClientName(item)
    }
    var btn = <button className="btn tertiary small" onClick={() => this.addBooked(item, name)}>Book in</button>
    if(this.state.item.data.max !== undefined && this.state.item.data.clients !== undefined) {
      if(this.state.item.data.max > 0 && this.state.item.data.clients.length >= this.state.item.data.max) {
        btn = <button className="btn tertiary small" onClick={() => this.addWaiting(item, name)}>{lang.t('calendar:button.addwaiting')}</button>
      }
      
    }
    if(this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.indexOf(item) !== -1) {
        if(Moment() < Moment(this.state.item.data.locked, 'X') || this.state.item.data.locked === undefined) {
          btn = <button className="btn tertiary small" onClick={() => this.removeBooked(item)}>{lang.t('calendar:button.cancelbooking')}</button>
        } else {
          btn = <span></span>
        }
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.indexOf(item) !== -1) {
        if(Moment() < Moment(this.state.item.data.locked, 'X')) {
          btn = <button className="btn tertiary small" onClick={() => this.removeWaiting(item)}>{lang.t('calendar:button.removewaiting')}</button>
        } else {
          btn = <span></span>
        }
      }
    }
    return btn
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      var list = global.userLinked
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:booking.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-20">{lang.t('modal:booking.text')}</p>
            <div className="client">
              <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
              <div className="text">
                <h4>{lang.t('common:label.yourself')}</h4>
                <p>{this.getStatus(global.spaceClient)}</p>
                {this.renderButton(global.spaceClient)}
              </div>
            </div>
            <div className="sv-10"></div>
            {list.map(item => (
              <div className="client mb-10">
                {HelperClients.getImage(this.getClient(item))}
                <div className="text">
                  <h4>{this.getClientName(item)}</h4>
                  <p>{this.getStatus(item)}</p>
                  {this.renderButton(item)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderStatus() {
    var label = lang.t('calendar:attendance.bookedin')
    var date = Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X')
    if(this.state.item.data.clients !== undefined && this.state.item.data.group) {
      if(this.state.item.data.clients.indexOf(global.spaceClient) !== -1) {
        if(parseInt(Moment().format('X')) > parseInt(date)) {
          label = lang.t('calendar:attendance.attended')
        }
        return (
          <div className="infobox small green mb-30">
            <p>{label}</p>
          </div>
        )
      } else {
        if(this.state.item.data.waiting !== undefined) {
          if(this.state.item.data.waiting.indexOf(global.spaceClient) !== -1 && parseInt(Moment().format('X')) < parseInt(date)) {
            return (
              <div className="infobox small yellow mb-30">
                <p>{lang.t('calendar:attendance.waiting')}</p>
              </div>
            )
          }
        }
      }
    } else if(!this.state.item.data.group && this.state.item.data.attendance === 3) {
      return (
        <div className="infobox small green mb-30">
          <p>{lang.t('calendar:event.attended')}</p>
        </div>
      )
    }
  }


  renderAttendeesList(type) {
    var label = lang.t('calendar:label.nobookings')
    if(this.state.item.data.availability) {
      label = lang.t('calendar:label.nobookings')
    }
    if(this.state.item.data.unlocked !== undefined && this.state.item.data.unlocked !== '01/01/1900 00:00') {
      if(Moment() < Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm')) {
        label = lang.t('calendar:label.bookingnotopen')
      }
    }
    if(type === 'past') {
      label = (this.state.avail ? lang.t('calendar:label.selectattendee') : lang.t('calendar:label.selectattendees'))
    }
    if(global.userShowBooked) {
      if(this.state.item.data.clients === undefined || this.state.item.data.clients.length === 0) {
        return (
          <p>{label}</p>
        )
      } else {
        return (
          <div>
            {this.state.item.data.clients.map(item => (
              <div className="client small" key={item}>
                {HelperClients.getImage(this.getClient(item))}
                <p>{item === global.spaceClient ? lang.t('common:label.you') : this.getClientName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderWaiting() {
    if(this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.length > 0) {
        return (
          <div className="mb-20">
            <label>{lang.t('calendar:label.waiting')}</label>
            {this.state.item.data.waiting.map(item => (
              <div className="client small" key={item}>
                {HelperClients.getImage(this.getClient(item))}
                <p>{item === global.spaceClient ? lang.t('common:label.you') : this.getClientName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderAttendance() {
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date && global.userShowBooked) {
      return (
        <div className="mb-20">
          <label>{now > date2 ? lang.t('calendar:label.attended') : lang.t('calendar:label.bookedin')} {this.state.max > 0 && !this.state.avail ? '('+this.state.max+' '+lang.t('common:label.max')+')' : ''}</label>
          {this.renderAttendeesList('past')}
        </div>
      )
    } else if(now < date && global.userShowBooked) {
      return (
        <div className="mb-20">
          <label className="lft">{this.state.item.data.attendance === 2 ? lang.t('calendar:label.bookedin') : lang.t('calendar:label.attending')} {this.state.max > 0 ? '('+this.state.max+' '+lang.t('common:label.max')+')' : ''}</label>
          <div className="clear"></div>
          {this.renderAttendeesList('future')}
        </div>
      )
    }
  }


  renderBookButton() {
    var max = this.state.item.data.max
    if(max === 0) {
      max = 99999
    }
    if(!global.userRestricted) {
      if(global.userLinked.length === 0) {
        if(this.state.item.data.clients === undefined) {
          return (
            <button className="btn primary" onClick={() => this.addBooked(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.bookin')}</button>
          )
        } else {
          if(this.state.item.data.clients.indexOf(global.spaceClient) !== -1) {
            return (
              <button className="btn secondary" onClick={() => this.removeBooked(global.spaceClient)}>{lang.t('calendar:button.cancelbooking')}</button>
            )
          } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length < max) {
            return (
              <button className="btn primary" onClick={() => this.addBooked(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.bookin')}</button>
            )
          } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length >= max) {
            var waiting = false
            if(this.state.item.data.waiting !== undefined) {
              if(this.state.item.data.waiting.indexOf(global.spaceClient) !== -1) {
                waiting = true
              }
            }
            if(waiting) {
              return (
                <button className="btn secondary" onClick={() => this.removeWaiting(global.spaceClient)}>{lang.t('calendar:button.leavewaiting')}</button>
              )
            } else {
              return (
                <div className="info">
                  <p>{lang.t('calendar:waiting.text')}</p>
                  <div className="sv-30"></div>
                  <button className="btn primary" onClick={() => this.addWaiting(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.waiting')}</button>
                </div>
              )
            }
          }
        }
      } else {
        return (
          <button className="btn primary" onClick={() => this.showOverlay()}>{lang.t('calendar:button.manage')}</button>
        )
      }
    }
  }


  renderBookOverride() {
    var max = this.state.item.data.max
    if(max === 0) {
      max = 99999
    }
    if(!global.userRestricted) {
      if(global.userLinked.length === 0) {
        if(this.state.item.data.clients === undefined) {
          return (
            <button className="btn primary" onClick={() => this.addBooked(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.bookin')}</button>
          )
        } else {
          if(this.state.item.data.clients.indexOf(global.spaceClient) !== -1) {
            // Can't cancel
          } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length < max) {
            return (
              <button className="btn primary" onClick={() => this.addBooked(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.bookin')}</button>
            )
          } else if(this.state.item.data.clients.indexOf(global.spaceClient) === -1 && this.state.item.data.clients.length >= max) {
            var waiting = false
            if(this.state.item.data.waiting !== undefined) {
              if(this.state.item.data.waiting.indexOf(global.spaceClient) !== -1) {
                waiting = true
              }
            }
            if(waiting) {
              // Can't leave waiting list
            } else {
              return (
                <div className="info">
                  <p>{lang.t('calendar:waiting.text')}</p>
                  <div className="sv-30"></div>
                  <button className="btn primary" onClick={() => this.addWaiting(global.spaceClient, lang.t('common:label.you'))}>{lang.t('calendar:button.waiting')}</button>
                </div>
              )
            }
          }
        }
      } else {
        return (
          <button className="btn primary" onClick={() => this.showOverlay()}>{lang.t('calendar:button.manage')}</button>
        )
      }
    }
  }


  renderMainButton() {
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(date2 > now) {
      return (
        <div>
          {this.renderBookButton()}
        </div>
      )
    }
  }


  renderVirtual() {
    if(this.state.item.data.link !== undefined && this.state.item.data.link !== '') {
      return <span className="pill primary" style={{marginLeft: 5, position: 'relative', top: '-2px'}}>{lang.t('calendar:label.virtual')}</span>
    }
  }


  renderLocation() {
    if(this.state.item.data.locationName !== undefined) {
      return (this.state.item.data.locationName === '' ? '' : ' - '+this.state.item.data.locationName)
    }
  }


  renderContent() {
    if(this.state.item === null) {
      return (
        <div></div>
      )
    } else {
      return (
        <div>
          <div className="col-6 mt-20">
            <div className="box">
              {this.renderStatus()}
              <h4 style={{marginBottom: 5}}>{this.getEventTime()}{this.renderVirtual()}</h4>
              <h3 className="mb-30">{Cal.getDuration(this.state.item.data.duration)} {this.renderLocation()}</h3>
              <div className="clear"></div>
              {this.renderAttendance()}
              {this.renderWaiting()}
              <div className="sv-20"></div>
              <div className="info">
                <label>{lang.t('calendar:event.description')}</label>
                <p>{this.state.item.data.desc === '' ? '-' : this.state.item.data.desc}</p>
              </div>
              <div className="sv-20"></div>
              {this.renderMainButton()}
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
            <div className="infobox">
              <p>{lang.t('calendar:event.booking')}</p>
            </div>
          </div>
          <div className="sv-40 clear"></div>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">
          {this.renderContent()}
        </div>
        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/calendar'} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{this.state.item === null ? 'Event' : this.state.item.data.title}</h2>
          </div>
        </div>
        <Navigation active='calendar' />
        {this.renderOverlay()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','common','calendar','modal','message'])(withRouter(withAuthorizationMember(condition)(MemberEventPage)));