import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'firebase/functions';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import Message from '../../components/Message';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorization } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberRunningPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      program: null,
      view: 'select',
      clients: global.clients,
      client: null,
      back: '/'+global.uid+'/training/sessions',
      hrs: [],
      min: [],
      link: '',
      current: 0,
      results: 0,
      round: 1,
      interval: 0,
      intmax: 0,
      timer: 'getready',
      end: 0,
      pos: global.timerStart,
      counter: null,
      volume: true,
      // Results
      values1: [],
      values2: [],
      valAR1: [],
      valAR2: [],
      notes: '',
      // Overlays
      margin: 0,
      showOverlayPause: false,
      hiddenPause: 'hidden',
      paused: false,
      showOverlayEnergy: false,
      hiddenEnergy: 'hidden',
      showOverlayAbort: false,
      hiddenAbort: 'hidden',
      showOverlayNotes: false,
      hiddenNotes: 'hidden',
      energy: [],
      levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      energyShown: false,
      best: null,
      bestGroup: null,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var sess = arr[arr.length-1]
    document.title = lang.t('header:title.session')+' - '+global.userBusiness

    if(id !== 'running' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()

    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())

    var tmp1 = []
    for(var i=0; i<60; i++) {
      if(i<10) {
        tmp1.push('0'+i)
      } else {
        tmp1.push(i)
      }
    }

    this.setState({
      min: tmp1,
      sec: tmp1,
      session: sess,
    })

    var audio1 = new Audio("/audio/audio1sec.mp3")
    audio1.preload = "auto"
    var audio2 = new Audio("/audio/audiostart.mp3")
    audio2.preload = "auto"
    var audio3 = new Audio("/audio/audioend.mp3")
    audio3.preload = "auto"
    this.configureData()
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.session')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    var back = '/'+global.uid+'/training/sessions'
    var item = null
    var program = null

    for(var session of global.sessionsTraining) {
      if(session.id === id) {
        item = session
        program = session.program[0]
        back = '/'+global.uid+'/training/program/'+session.program[0].id
        
      }
    }

    this.setState({
      id: id,
      clients: global.clients,
      clientsList: global.clients,
      back: back,
      item: item,
      program: program,
      best: global.clientBest
    }, () => {
      if(this.state.current === 0 && this.state.item !== null && !this.state.energyShown) {
        this.setState({
          energyShown: true
        })
        setTimeout(() => {
          this.showOverlayEnergy()
        }, 500);
      }
    })
  }



  // Configuration ------------------------------------------------------------



  getSessionName() {
    var label = lang.t('header:title.session')+' - '
    if(this.state.item !== null) {
      label = lang.t('calendar:label.training')+" - "+Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('hh:mm a')
    }
    return label
  }


  getSetsName(item) {
    var label = lang.t('training:prog.set')
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('training:prog.superset')
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('training:prog.dropset')
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('training:prog.dropset')
      } else {
        label = lang.t('training:prog.superset')
      }
    }
    return label
  }


  getBlockHeader(block) {
    if(this.state.program !== null) { 
      var item = block.data
      var name = lang.t(global.proTypes[item.type])
      var label = ''
      var s = lang.t('training:prog.rounds')
      if((item.rounds === 1 && item.type !== 5) || (item.cycles === 1 && item.type === 5)) {
        s = lang.t('training:prog.round')
      }
      if(item.type === 0) {
        label = ' - '+HelperCal.getDurationMin(item.rounds)
      }
      if(item.type === 1) {
        label = ' - '+item.rounds+' '+s
        
        if(item.emom) {
          label = ' - '+item.rounds+' '+s+' '+lang.t('training:prog.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))
        }
      }
      if(item.type === 2 || item.type === 3) {
        label = ' - '+item.rounds+' '+s
        if(item.type === 2 && item.emom) { 
          if(item.exRest[0] === 0) {
            label += ' ('+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('training:prog.work')+')'
          } else {
            label += ' ('+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' - '+HelperCal.getDurationMinAlt(parseInt(item.exRest[0]))+')'
          }
        }
      }
      if(item.type === 4) {
        label = ' - '+item.rounds+' '+this.getSetsName(item)+'s'
        if(item.rounds === 1) {
          label = ' - 1 '+this.getSetsName(item)
        }
      }
      if(item.type === 5) {
        label = ' - '+HelperCal.getDurationMin(item.rounds)
        if(item.cycles > 1) {
          label = ' - '+item.cycles+' '+s+' - '+lang.t('training:prog.cap')+': '+HelperCal.getDurationMin(item.rounds)
        }
      }
      return name+' '+label
    }
  }


  getInterval(block, index) {
    if(!block.data.emom && (block.data.type === 1 || block.data.type === 2)) {
      if(block.data.exRest[index] !== 0) {
        return (
          <span><br/>{HelperCal.getDurationMin(block.data.exWork[index])} work - {HelperCal.getDurationMin(block.data.exRest[index])} rest</span>
        )
      } else {
        return (
          <span><br/>{HelperCal.getDurationMin(block.data.exWork[index])}</span>
        )
      }
      
    }
  }


  getClient(id) {
    var client = null
    for(var item of this.state.clients) {
      if(item.id === id) {
        client = item
      }
    }
    return client
  }


  getClientName(id, type) {
    var label = ''
    for(var item of this.state.clients) {
      if(item.id === id) {
        var ar = item.data.name.split(' ')
        if(type === 'first') {
          label = ar[0]
        } else {
          if(ar.length > 1) {
            for(var i=1; i<ar.length; i++) {
              label += ar[i]+' '
            }
          }
        }
      }
    }
    return label
  }


  getExInfo(block, index) {
    var label = '-'
    var weight = ''
    var unit1 = lang.t('training:prog.reps')
    var word = ' '+lang.t('training:prog.with')+' '
    var unit2 = block.data.exWeightType
    if(block.data.exWeightType === 'per' || block.data.exWeightType === undefined) {
      unit2 = (global.spaceLbs ? 'lb' : 'kg')
    }
    if(block.data.exUnits !== undefined) {
      if(block.data.exUnits[index] === 'dist') {
        unit1 = 'm'
      }
      if(block.data.exUnits[index] === 'cals') {
        unit1 = 'cal'
      }
      if(block.data.exUnits[index] === 'time') {
        unit1 = 's'
      }
    } else {
      if(block.data.exTool[index] === 6 || block.data.exTool[index] === 7) {
        unit1 = 'm'
      }
      if(block.data.exTool[index] === 27) {
        unit1 = 'cal'
      }
    }
    if(block.data.exWeightType !== undefined) {
      if(block.data.exWeightType[index] === 'per') {
        word = ' '+lang.t('training:prog.at')+' '
        unit2 = '%'
      }
    }
    if(block.data.exReps[index] !== 0) {
      label = block.data.exReps[index]+' '+unit1
    }
    if(block.data.exRepsRounds !== undefined) {
      if(block.data.exRepsRounds[index] !== '0' && block.data.exRepsRounds[index] !== '') {
        label = block.data.exRepsRounds[index]+' '+unit1
      }
      
    }
    if(block.data.exWeight[index] !== 0) {
      weight = word+block.data.exWeight[index]+' '+unit2
    }
    if(block.data.exWeightRounds !== undefined) {
      if(block.data.exWeightRounds[index] !== '0.0' && block.data.exWeightRounds[index] !== '' && block.data.exWeightRounds[index] !== '0') {
        weight = word+block.data.exWeightRounds[index]+' '+unit2
      }
    }
    return label+weight
  }


  getTimerName() {
    var label = lang.t('training:running.getready')
    if(this.state.timer === 'work') {
      label = lang.t('training:label.work')
      if(this.state.program.blocks[this.state.current].data.type === 3) {
        label = lang.t('training:label.rest')+'Work '+(this.state.interval+1)+' '+lang.t('training:prog.of')+' 8'
      }
    } else if(this.state.timer === 'rest') {
      label = lang.t('training:label.rest')
      if(this.state.program.blocks[this.state.current].data.type === 3) {
        label = lang.t('training:label.rest')+' '+(this.state.interval+1)+' '+lang.t('training:prog.of')+' 8'
      }
    }
    return label
  }


  displayTimer() {
    var label = this.state.pos
    if(this.state.timer !== 'getready') {
      var min = Math.floor(this.state.pos/60)
      var sec = this.state.pos-min*60
      if(min < 10) {
        min = '0'+min
      }
      if(sec < 10) {
        sec = '0'+sec
      }
      label = min+':'+sec
    }
    return label
  }



  // Timer control ------------------------------------------------------------



  startTimer() {
    if(this.state.program.blocks[this.state.current].data.type !== 4) {
      this.setState({
        view: 'timer',
        timer: 'getready',
        end: 0,
        pos: global.timerStart,
        round: 1,
        interval: 0,
      })
      this.counter = setInterval(this.count.bind(this), 1000);
    } else {
      this.setState({
        view: 'sets',
        timer: 'rest',
        round: 1,
        end: 0,
        pos: global.timerStart,
      })
    }
    
  }


  configureTimer() {
    var block = this.state.program.blocks[this.state.current]
    var start = 0
    var end = 0
    var intmax = 0
    if(block.data.type === 0) {
      start = block.data.rounds
    } else if(block.data.type === 1) {
      start = block.data.exWork[this.state.interval]
    } else if(block.data.type === 2) {
      start = block.data.exWork[this.state.interval]
      if(block.data.emom) {
        intmax = 1
      } else {
        intmax = block.data.exName.length
      }
    } else if(block.data.type === 3) {
      start = block.data.exWork[this.state.interval]
      intmax = 8
    } else if(block.data.type === 5) {
      start = 0
      end = block.data.rounds
    }
    this.setState({
      timer: 'work',
      pos: start,
      end: end,
      intmax: intmax
    })
    this.counter = setInterval(this.count.bind(this), 1000);
  }


  count() {
    var tmp = this.state.pos
    var block = this.state.program.blocks[this.state.current].data
    if(block.type === 5 && this.state.timer === 'work') {
      tmp++
    } else {
      tmp--
    }
    if(this.state.showOverlayPause) {
      clearInterval(this.counter)
    }
    
    this.setState({
      pos: tmp
    })
    if(tmp > 0 && tmp < 4 && this.state.timer === 'getready') {
      var audio1 = new Audio("/audio/audio1sec.mp3")
      if(!this.state.volume) {
        audio1.volume = 0
      }
      audio1.play()
    }

    if(tmp === this.state.end-1 || tmp === this.state.end+1) {
      setTimeout(() => {
        var audio = new Audio("/audio/audioend.mp3")
        if(this.state.timer === 'getready') {
          audio = new Audio("/audio/audiostart.mp3")
        }
        if(!this.state.volume) {
          audio.volume = 0
        }
        audio.play()
      }, 950);
      
    }

    if(tmp === this.state.end) {
      clearInterval(this.counter)
      setTimeout(() => {
        if(this.state.timer === 'getready') {
          this.configureTimer()
        } else {
          // AMRAP & For Time
          if(block.type === 0 || block.type === 5) {
            this.endTimer()
          // EMOM
          } else if(block.type === 1) {
            if(this.state.round === block.rounds && (this.state.interval === block.exWork.length-1 || block.emom)) {
              this.endTimer()
            } else {
              if((this.state.interval === block.exWork.length-1 || block.emom) && this.state.round <= block.rounds) {
                var tmp = this.state.round
                tmp++
                this.setState({
                  pos: block.exWork[0],
                  end: 0,
                  interval: 0,
                  round: tmp
                })
                this.counter = setInterval(this.count.bind(this), 1000);
              } else if(this.state.interval < block.exWork.length-1 && !block.emom && this.state.round <= block.rounds) {
                var tmp2 = this.state.interval
                tmp2++
                this.setState({
                  pos: block.exWork[tmp2],
                  end: 0,
                  interval: tmp2,
                })
                this.counter = setInterval(this.count.bind(this), 1000);
              }
            }
          // Intervals & Tabata
          } else if(block.type === 2 || block.type === 3) {
            this.configureInterval()
          } else if(block.type === 4) {
            this.setState({
              view: 'sets',
              timer: 'rest',
            })
          }
        }
      }, 1000);
      
    }
  }


  configureInterval() {
    var block = this.state.program.blocks[this.state.current].data
    if(this.state.timer === 'work') {
      var pos = block.exRest[this.state.interval]
      if(block.type === 3) {
        pos = 10
      }
      this.setState({
        pos: pos,
        end: 0,
        timer: 'rest'
      })
      if(pos === 0) {
        this.configureInterval()
      } else {
        this.counter = setInterval(this.count.bind(this), 1000);
      }
    } else {
      if(this.state.interval < this.state.intmax-1) {
        var tmp = this.state.interval
        tmp++
        var pos2 = block.exWork[tmp]
        if(block.type === 3) {
          pos2 = 20
        }
        this.setState({
          pos: pos2,
          end: 0,
          timer: 'work',
          interval: tmp
        })
        this.counter = setInterval(this.count.bind(this), 1000);
      } else {
        var tmp3 = this.state.round
        if(this.state.round < block.rounds) {
          tmp3++
          this.setState({
            pos: block.exWork[0],
            end: 0,
            timer: 'work',
            interval: 0,
            round: tmp3
          })
          this.counter = setInterval(this.count.bind(this), 1000);
        } else {
          this.endTimer()
        }
      }
    }
  }


  endTimer() {
    var tmp = this.state.current
    tmp++
    this.setState({
      view: 'select',
      timer: 'getready',
      current: tmp,
      showOverlayNotes: false
    })
  }


  abortTimer() {
    clearInterval(this.counter)
    this.endTimer()
  }


  toggleAudio() {
    var tmp = true
    if(this.state.volume === true) {
      tmp = false
    }
    this.setState({
      volume: tmp
    })
  }



  // Sets & Results ------------------------------------------------------------



  finishSet() {
    var block = this.state.program.blocks[this.state.current].data
    if(block.rounds > 1 && this.state.round < block.rounds) {
      var tmp = this.state.round
      tmp++
      if(block.exWork[0] === 0) {
        this.setState({
          round: tmp
        })
      } else {
        this.setState({
          pos: block.exWork[0],
          end: 0,
          timer: 'rest',
          view: 'timer',
          round: tmp
        })
        this.counter = setInterval(this.count.bind(this), 1000);
      }
    } else {
      this.endTimer()
    }
  }


  showResults(index) {
    var block = this.state.program.blocks[index].data
    var tmp1 = []
    var tmp2 = []
    var tmp3 = []
    var tmp4 = []
    for(var i=0; i<block.exId.length; i++) {
      if(block.exResRepsRounds !== undefined && (block.type === 1 || block.type === 5)) {
        var rv = block.exResRepsRounds[i]
        if(block.exResRepsRounds[i].charAt(block.exResRepsRounds[i].length - 1) === '-') {
          rv = block.exResRepsRounds[i].substring(0, block.exResRepsRounds[i].length - 1)
        }
        tmp1.push(rv)
      } else {
        tmp1.push(String(block.exResReps[i]))
      }
      if(block.exResWeightRounds !== undefined && (block.type === 1 || block.type === 5)) {
        var wv = block.exResWeightRounds[i]
        if(block.exResWeightRounds[i].charAt(block.exResWeightRounds[i].length - 1) === '-') {
          wv = block.exResWeightRounds[i].substring(0, block.exResWeightRounds[i].length - 1)
        }
        tmp2.push(wv)
      } else {
        tmp2.push(String(block.exResWeight[i]))
      }
      if(block.type === 5) {
        if(block.timeRes !== undefined) {
          var min2 = parseInt(Math.floor(block.timeRes/60))
          var sec2 = block.timeRes-min2*60
          tmp3.push(min2)
          tmp4.push(sec2)
        } else {
          tmp3.push(0)
          tmp4.push(0)
        }
      }
    }
    if(block.type === 0) {
      tmp3.push(this.getAmrap(0, 'rounds', index))
      tmp4.push(this.getAmrap(0, 'reps', index))
    }
    this.setState({
      view: 'results',
      results: index,
      values1: tmp1,
      values2: tmp2,
      valAR1: tmp3,
      valAR2: tmp4,
      notes: block.notesRes
    })
  }


  hideResults() {
    this.setState({
      view: 'select'
    })
  }



  // Pause overlay ------------------------------------------------------------



  showOverlayPause() {
    this.setState({
      showOverlayPause: true,
      paused: true
    })
    clearInterval(this.counter)
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenPause: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayPause() {

    const height = this.divElement.clientHeight
    this.setState({
      hiddenPause: 'hidden',
      paused: false,
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayPause: false});
    }, 500);
  }


  renderOverlayPause() {
    if(this.state.showOverlayPause) {
      return (
        <div className={'overlay '+this.state.hiddenPause}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:pause.title')}</h2>
            <p className="clear mb-30">{lang.t('modal:pause.text')}</p>
            <button className="btn primary" onClick={() => {this.hideOverlayPause(); clearInterval(this.counter); this.counter = setInterval(this.count.bind(this), 1000);}}>{lang.t('modal:pause.button1')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary width-12" onClick={() => {this.hideOverlayPause(); this.startTimer()}}>{lang.t('modal:pause.button2')}</button>
          </div>
        </div>
      )
    }
  }



  // Start and end session ------------------------------------------------------------



  endSession() {
    if(this.state.current < this.state.program.blocks.length) {
      this.showOverlayAbort()
    } else {
      this.showOverlayEnergy()
    }
  }


  showOverlayAbort() {
    this.setState({
      showOverlayAbort: true,
      paused: true
    })
    clearInterval(this.counter)
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenAbort: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayAbort() {
    const height = this.divElement.clientHeight
    if(this.state.status === 'running' || this.state.status === 'getready') {
      this.countTimer(this.state.time)
    }
    this.setState({
      hiddenAbort: 'hidden',
      paused: false,
      margin: -height/2+100
    })
    setTimeout(() => {
      if(this.state.view === 'timer') {
        this.counter = setInterval(this.count.bind(this), 1000);
      }
      
      this.setState({showOverlayAbort: false});
    }, 500);
  }


  renderOverlayAbort() {
    if(this.state.showOverlayAbort) {
      return (
        <div className={'overlay '+this.state.hiddenAbort}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:abort.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => {this.hideOverlayAbort()}}>{lang.t('common:button.cancel')}</button>
            <p className="clear mb-30">{lang.t('modal:abort.text')}</p>
            <Link to={this.state.back} className="btn primary">{lang.t('modal:abort.button')}</Link>
          </div>
        </div>
      )
    }
  }


  showOverlayEnergy() {
    var energy = []
    energy.push(0)
    this.setState({
      showOverlayEnergy: true,
      energyShown: true
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenEnergy: '',
        margin: -height/2,
        energy: energy,
      });
    }, 100);
  }


  hideOverlayEnergy() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenEnergy: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayEnergy: false});
    }, 500);
  }


  renderOverlayEnergy() {
    if(this.state.showOverlayEnergy) {
      return (
        <div className={'overlay '+this.state.hiddenEnergy}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:energy.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.skipEnergy()}>{lang.t('modal:energy.skip')}</button>
            <p className="clear mb-20">{this.state.current === 0 ? lang.t('modal:energy.text1') : lang.t('modal:energy.text2')}</p>
            <div className="radios">
              {this.state.levels.map((item, index) => (
                <button className={this.state.energy[0] === item ? 'radio col active' : 'radio col'} style={{float: 'left', width: '10%'}} key={item} onClick={() => this.setEnergy(item, 0)}>{item}</button>
              ))}
            </div>
            <div className="sv-30"></div>
            <button className="btn primary" onClick={() => this.saveEnergy()}>{this.state.current === 0 ? lang.t('modal:energy.button1') : lang.t('modal:energy.button2')}</button>
          </div>
        </div>
      )
    }
  }


  setEnergy(value, index) {
    var tmp = this.state.energy
    tmp[index] = value
    this.setState({
      energy: tmp
    })
  }


  skipEnergy() {
    this.hideOverlayEnergy()
    if(this.state.current !== 0) {
      this.props.history.push(this.state.back)
    }
  }


  saveEnergy() {
    var label = ''
    for(var item of this.state.energy) {
      label += '-'+item
    }
    if(this.state.current === 0) {
      Firebase.database().ref('/sessions/'+global.uidUser+'/'+this.state.id).update({
        energyStart: label,
      }).then((data)=>{
        this.hideOverlayEnergy()
        EventEmitter.dispatch('showMessage', lang.t('message:energy'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/sessions/'+global.uidUser+'/'+this.state.id).update({
        energyEnd: label,
      }).then((data)=>{
        this.hideOverlayEnergy()
        global.message = lang.t('message:energy')
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }


  showOverlayNotes() {
    this.setState({
      showOverlayNotes: true,
    })
  }


  hideOverlayNotes() {
    this.setState({
      showOverlayNotes: false
    })
  }


  renderOverlayNotes() {
    if(this.state.showOverlayNotes) {
      return (
        <div className="run-onotes">
          <h2 className="mb-20 lft">{lang.t('training:label.notes')}</h2>
          <button className="btn tertiary small close rgt" onClick={() => {this.hideOverlayNotes()}}>{lang.t('modal:common.close')}</button>
          <p className="clear" style={{whiteSpace: 'pre-wrap'}}>{this.state.program.blocks[this.state.current].data.notes}</p>
          <div className="clear sv-20"></div>
        </div>
      )
    }
  }



  // Results stuff ------------------------------------------------------------



  renderExResult(client) {
    return (
      <div>
        {this.renderAmrap(client)}
        {this.renderTime(client)}
        {this.state.program.blocks[this.state.results].data.exId.map((item, index) => (
          <div key={index}>
            {this.renderExFields(index, client)}
          </div>
        ))}
      </div>
    )
  }


  renderAmrap(client) {
    if(this.state.program.blocks[this.state.results].data.type === 0) {
      return (
        <div className="item">
          <p className="title"><strong>{lang.t('training:running.roundsreps')}</strong></p>
          <div className="first">
            <p>{lang.t('training:prog.rounds2')}</p>
            <input type="number" value={this.state.valAR1[client]} onChange={(val) => this.onChangeRoundsAR(val, client)}/>
          </div>
          <div className="second">
            <p>{lang.t('training:label.reps')}</p>
            <input type="number" value={this.state.valAR2[client]} onChange={(val) => this.onChangeRepsAR(val, client)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderTime(client) {
    if(this.state.program.blocks[this.state.results].data.type === 5) {
      return (
        <div className="item">
          <p className="title"><strong>{lang.t('training:label.completiontime')}</strong></p>
          <div className="first">
            <p>{lang.t('training:running.minutes')}</p>
            <input type="number" value={this.state.valAR1[client]} onChange={(val) => this.onChangeMin(val, client)}/>
          </div>
          <div className="second">
            <p>{lang.t('training:running.seconds')}</p>
            <input type="number" value={this.state.valAR2[client]} onChange={(val) => this.onChangeSec(val, client)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  getFieldPlaceholder(index, type) {
    var label = ''
    var block = this.state.program.blocks[this.state.results]
    var cycles = 1
    if(block.data.cycles !== undefined) {
      cycles = block.data.cycles
    }
    if(((block.data.type === 1 || block.data.type === 4) && block.data.rounds > 1) || (block.data.type === 5 && cycles > 1)) {
      if(type === 'reps') {
        label = lang.t('training:running.eg1')
      } else {
        label = lang.t('training:running.eg2')
      }
    }
    return label
  }


  renderExRepsTitle(block, index) {
    var label = 'Total reps'
    if(block.exUnits !== undefined) {
      if(block.exUnits.length > index) {
        if(block.exUnits[index] === 'dist') {
          label = 'Total distance (m)'
        }
        if(block.exUnits[index] === 'cals') {
          label = 'Total calories'
        }
        if(block.exUnits[index] === 'time') {
          label = 'Total time (s)'
        }
      }
    } else {
      if(block.exTool[index] === 6 || block.exTool[index] === 7 || block.exTool[index] === 25) {
        label = 'Total distance (m)'
      }
    }
    return label
  }


  renderExFields(index, client) {
    var block = this.state.program.blocks[this.state.results].data
    if(block.type === 0) {
      if(global.exToolsWeight.indexOf(block.exTool[index]) !== -1) {
        return (
          <div className="item">
            <p className="title"><strong>{block.exName[index]}</strong></p>
            <p>{lang.t('training:label.weightused')} (({block.exWeightType[index] !== 'per' ? block.exWeightType[index] : (global.spaceLbs ? 'lb' : 'kg')}))</p>
            <input type="text" value={this.getFieldWeight(index, client)} onChange={(val) => this.onChangeWeight(val, index, client)}/>
          </div>
        )
      }
    } else {
      if(block.exTool[index] === 0 || block.exTool[index] === 6 || block.exTool[index] === 7 || block.exTool[index] === 9 || block.exTool[index] === 11 || block.exTool[index] === 25) {
        return (
          <div className="item">
            <p className="title"><strong>{block.exName[index]}</strong></p>
            <p>{this.renderExRepsTitle(block, index)}</p>
            <input type="text" value={this.getFieldReps(index, client)} onChange={(val) => this.onChangeReps(val, index, client)} placeholder={this.getFieldPlaceholder(index, 'reps')}/>
          </div>
        )
      } else {
        return (
          <div className="item">
            <p className="title"><strong>{block.exName[index]}</strong></p>
            <div className="first">
              <p>{this.renderExRepsTitle(block, index)}</p>
              <input type="text" value={this.getFieldReps(index, client)} onChange={(val) => this.onChangeReps(val, index, client)} placeholder={this.getFieldPlaceholder(index, 'reps')}/>
            </div>
            <div className="second">
            <p>{lang.t('training:label.weightused')} (({block.exWeightType[index] !== 'per' ? block.exWeightType[index] : (global.spaceLbs ? 'lb' : 'kg')}))</p>
              <input type="text" value={this.getFieldWeight(index, client)} onChange={(val) => this.onChangeWeight(val, index, client)} placeholder={this.getFieldPlaceholder(index, 'weight')}/>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  getFieldReps(index, client) {
    var block = this.state.program.blocks[this.state.results].data
    var label = ''
    if(block.type !== 1 && block.type !== 4 && block.type !== 5) {
      if(this.state.values1[index] !== 0 && block.exResReps !== undefined) {
        label = this.state.values1[index]
      }
    } else {
      if(this.state.values1[index] !== 0 && block.exResReps !== undefined && this.state.values1[index] !== "0") {
        label = this.state.values1[index]
      }
    }
    if(label === 'NaN' || label === 'undefined') {
      label = ''
    }
    return label
  }


  getFieldWeight(index, client) {
    var block = this.state.program.blocks[this.state.results].data
    var label = ''
    if(block.type !== 1 && block.type !== 4 && block.type !== 5) {
      if(this.state.values2[index] !== 0 && this.state.values2[index] !== 0.0 && block.exResWeight !== undefined && this.state.values2[index] !== "0") {
        label = this.state.values2[index]
      }
    } else {
      if(this.state.values2[index] !== 0 && block.exResReps !== undefined && this.state.values2[index] !== "0") {
        label = this.state.values2[index]
      }
    }
    if(label === 'NaN' || label === 'undefined') {
      label = ''
    }
    return label
  }


  onChangeReps(event, index, client) {
    var block = this.state.program.blocks[this.state.results].data
    var tmp = this.state.values1
    var tmp2 = event.target.value
    var val1 = tmp2.replace(/[^0-9-]/g, "")
    var val = val1.replace("--", "-")
    if(val === "") { val = "0" }
    if(block.type !== 1 && block.type !== 4 && block.type !== 5) {
      val = val.replaceAll("-", "")
      tmp[index] = parseInt(val)
    } else {
      if((block.rounds === 1 && block.type !== 5) || (block.cycles === 1 && block.type === 5)) {
        val = val.replace("-", "")
      }
      tmp[index] = val
    }
    this.setState({
      values1: tmp
    })
  }


  onChangeWeight(event, index, client) {
    var block = this.state.program.blocks[this.state.results].data
    var tmp = this.state.values2
    var tmp2 = event.target.value
    var val1 = tmp2.replace(/[^0-9-.]/g, "")
    var val = val1.replace('--', '-')
    val = val1.replace('.-', '-')
    val = val1.replace('. ', '')
    val = val1.replace('..', '.')
    if(val === "") { val = "0" }
    if(block.type !== 1 && block.type !== 4 && block.type !== 5) {
      val = val.replaceAll("-", "")
      tmp[index] = parseFloat(val)
    } else {
      if((block.rounds === 1 && block.type !== 5) || (block.cycles === 1 && block.type === 5)) {
        val = val.replaceAll("-", "")
      }
      tmp[index] = val
    }
    this.setState({
      values2: tmp
    })
  }


  getAmrap(client, type, index) {
    var label = 0
    var total = 0
    var target = 0
    for(var ex of this.state.program.blocks[index].data.exReps) {
      target += ex
    }
    for(var rex of this.state.program.blocks[index].data.exResReps) {
      total += rex
    }
    var rnds = parseInt(Math.floor(total/target))
    var reps = total-rnds*target
    if(type === 'rounds') {
      label = parseInt(rnds)
    } else {
      label = parseInt(reps)
    }
    return label
  }


  onChangeRoundsAR(event, client) {
    var tmp = this.state.valAR1
    tmp[0] = parseInt(event.target.value)
    if(event.target.value === '') {
      tmp[0] = 0
    }
    
    this.setState({
      valAR1: tmp
    })
  }


  onChangeRepsAR(event, client) {
    var tmp = this.state.valAR2
    tmp[0] = parseInt(event.target.value)
    if(event.target.value === '') {
      tmp[0] = 0
    }
    this.setState({
      valAR2: tmp
    })
  }


  onChangeMin(event, client) {
    var tmp = this.state.valAR1
    tmp[0] = parseInt(event.target.value)
    if(event.target.value === '') {
      tmp[0] = 0
    }
    
    this.setState({
      valAR1: tmp
    })
  }


  onChangeSec(event, client) {
    var tmp = this.state.valAR2
    tmp[0] = parseInt(event.target.value)
    if(event.target.value === '') {
      tmp[0] = 0
    }
    
    this.setState({
      valAR2: tmp
    })
  }


  onChangeNotes(event) {
    this.setState({
      notes: event.target.value
    })
  }


  saveResults() {
    var block = this.state.program.blocks[this.state.results]
    for(var i=0; i<block.data.exId.length; i++) {
      var type = "per"
      if(block.data.exWeightType !== undefined) {
        type = block.data.exWeightType[i]
      }
      var ex2 = ({
        id: block.data.exId[i],
        name: block.data.exName[i],
        tool: block.data.exTool[i],
        weight: block.data.exWeight[i],
        weightType: type,
        resWeight: this.state.values2[i],
      })
      this.updateBest(ex2)
    }
    
    // 1:1
    if(block.data.type === 2 || block.data.type === 3) {
      var rr = []
      var rw = []
      for(var vs1 of this.state.values1) {
        rr.push(parseInt(vs1))
      }
      for(var vs2 of this.state.values2) {
        rw.push(parseFloat(vs2))
      }
      Firebase.database().ref('/sessions/'+global.uidUser+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
        exResReps: rr,
        exResWeight: rw,
        notesRes: this.state.notes,
      })
    } else if(block.data.type === 0) {
      var reps3 = []
      var sub3 = 0
      for(var i3=0; i3<block.data.exReps.length; i3++) {
        var sum3 = parseInt(block.data.exReps[i3])
        var val31 = this.state.valAR1[0]
        var val32 = this.state.valAR2[0]
        var add3 = 0
        if((val32-sub3) > 0) {
          add3 = parseInt(val32-sub3)
          if(add3 > sum3) {
            add3 = sum3
          }
        }
        sub3 += parseInt(block.data.exReps[i3])
        reps3.push(parseInt(val31*sum3+add3))
      }
      var vals = []
      for(var vl of this.state.values2) {
        vals.push(parseFloat(vl))
      }
      Firebase.database().ref('/sessions/'+global.uidUser+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
        exResReps: reps3,
        exResWeight: vals,
        notesRes: this.state.notes,
      })
    } else {
      var reps4 = []
      var weight4 = []
      var rfinal = []
      var wfinal = []

      for(var v1 of this.state.values1) {
        if(v1 !== '' && v1 !== 0) {
          if(v1[0] === '-') {
            v1 = v1.substring(1, v1.length)
          }
          if(v1[v1.length-1] === '-') {
            v1 = v1.substring(0, v1.length-1)
          }

          var ar4 = v1.split('-')
          var n1 = 0
          for(var a41 of ar4) {
            n1 += parseInt(a41)
          }
          reps4.push(n1)
        } else {
          reps4.push(0)
        }
        rfinal.push(v1)
      }
      for(var v2 of this.state.values2) {
        if(v2 !== '' && v2 !== 0) {
          if(v2[0] === '-') {
            v2 = v2.substring(1, v2.length)
          }
          if(v2[v2.length-1] === '-') {
            v2 = v2.substring(0, v2.length-1)
          }

          var ar42 = v2.split('-')
          var sav = parseFloat(ar42[0])
          for(var a2 of ar42) {
            if(parseFloat(a2) > sav) { sav = parseFloat(a2) }
          }
          weight4.push(sav)
        } else {
          weight4.push(0)
        }
        wfinal.push(v2)
      }
      Firebase.database().ref('/sessions/'+global.uidUser+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
        exResReps: reps4,
        exResWeight: weight4,
        exResRepsRounds: rfinal,
        exResWeightRounds: wfinal,
        notesRes: this.state.notes,
      })
    }
    if(block.data.type === 5) {
      var min1 = 0
      var sec1 = 0
      if(this.state.valAR1[0] !== '') {
        min1 = parseInt(this.state.valAR1[0])*60
      } 
      if(this.state.valAR2[0] !== '') {
        sec1 = parseInt(this.state.valAR2[0])
      } 
      var time1 = min1+sec1
      Firebase.database().ref('/sessions/'+global.uidUser+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
        timeRes: parseInt(time1),
      })
    }

    this.hideResults()
    EventEmitter.dispatch('showMessage', lang.t('message:results'));
  }


  updateBest(ex) {
    var update = true
    var final = 0
    var actual = 0
    var weight = 0
    var tmp = this.state.best
    var type = "kg"
    if(global.spaceLbs) {
        type = "lb"
    }
    if(ex.weightType !== "per") {
        type = ex.weightType
    }

    if(String(ex.resWeight).indexOf('-') !== -1) {
      var arr = ex.resWeight.split('-')
      for(var val of arr) {
        if(parseFloat(val) > weight) {
          weight = parseFloat(val)
        }
      }
      final = parseFloat(weight)
      actual = parseFloat(weight)
    } else {
      final = parseFloat(ex.resWeight)
      actual = parseFloat(ex.resWeight)
      weight = parseFloat(ex.resWeight)
    }
    
    var per = 0
    //for(var item of global.clientBest) {
    for(var item of tmp) {
      if(item.id === ex.id) {
        if(ex.tool !== 6 && ex.tool !== 7) {
          if(ex.weight > 0 && ex.weightType === 'per') {
            final = weight*(100/ex.weight)
            per = ex.weight
          }

          var rval = item.data.value
          var rtype = ""
          if(item.data.type !== undefined) {
            rtype = item.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = item.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = item.data.value*global.lbsDown
          }
          if(rval > final) {
            update = false
          }
        }
        if(item.data.value < weight && (ex.tool === 6 || ex.tool === 7)) {
          update = false
        }
        if(update) {
          item.data.value = final
          this.setState({
            best: tmp
          })
        }
      }
    }

    if(final === 0) {
      update = false
    }

    var unit = ''
    if(ex.unit !== undefined) {
      unit = ex.unit
    }
    if(update && ex.tool !== 0 && ex.tool !== 9) {
      Firebase.database().ref('/records/'+global.uidUser+'/'+ex.id).update({
        date: Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm'),
        id: ex.id,
        name: ex.name,
        tool: ex.tool,
        value: final,
        actual: actual,
        percent: per,
        unit: unit,
        type: type
      })
    }
  }



  // Display stuff ------------------------------------------------------------



  renderExNotes(item, index) {
    if(item.data.exNotes !== undefined) {
      if(item.data.exNotes[index] !== undefined) {
        return (
          <span><br/>{item.data.exNotes[index]}</span>
        )
      }
    }
  }

  
  renderExBlock(block) {
    if(block.data.simple) {
      return (
        <div className={block === this.state.program.blocks[this.state.current] ? 'none' : ''}>
          <p className="mnotes" style={{opacity: .3}}>{block.data.notes}</p>
        </div>
      )
    } else {
      return (
        <div>
          {block.data.exName.map((item, index) => (
          <div className="item" key={index}>
            <strong>{item}</strong><br/>{this.getExInfo(block, index)}{this.getInterval(block, index)}{this.renderExNotes(block, index)}
          </div>
          ))}
        </div>
      )
    }
  }


  renderExOpacity(index) {
    var opacity = 1
    var block = this.state.program.blocks[this.state.current].data
    if(block.type === 1 && !block.emom && index !== this.state.interval) {
      opacity = 0.4
    } else if(block.type === 2 && !block.emom && index !== this.state.interval) {
      opacity = 0.4
    } else if(block.type === 3) {

    }
    return opacity
  }


  renderResultButton(index) {
    if(this.state.program.blocks[index].data.logResults !== false && this.state.program.blocks[index].data.simple === undefined) {
      return (
        <button className="btn tertiary width-12" onClick={() => this.showResults(index)}>{lang.t('training:button.editresults')}</button>
      )
    }
  }


  renderCurrentNotes() {
    if(this.state.program.blocks[this.state.current].data.notes !== '') {
      return (
        <p className="mnotes">{this.state.program.blocks[this.state.current].data.notes}</p>
      )
    }
  }


  renderCurrentBlockTitle() {
    let item = this.state.program.blocks[this.state.current]
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderCurrent() {
    if(this.state.current < this.state.program.blocks.length) {
      return (
        <div className="block">
          <div className="run-bar">{this.renderCurrentBlockTitle()}</div>
          <h2>{this.getBlockHeader(this.state.program.blocks[this.state.current])}</h2>
          <div className="main">
            {this.renderExBlock(this.state.program.blocks[this.state.current])}
            {this.renderCurrentNotes()}
          </div>
          <div className="cbutton">
            <button className="btn primary" onClick={() => this.startTimer()}>{lang.t('training:button.start')}</button>
          </div>
        </div>
      )
    }
  }


  renderBlockTitle(item) {
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderBlocks() {
    if(this.state.view === 'select' && this.state.item !== null) {
      var list1 = []
      var list2 = []
      for(var i=0; i<this.state.program.blocks.length; i++) {
        if(i<this.state.current) {
          list1.push(this.state.program.blocks[i])
        }
        if(i>this.state.current) {
          list2.push(this.state.program.blocks[i])
        }
      }
      return (
        <div className="run-blocks">
          <div style={{height: '400px', width: this.state.program.blocks.length*300, display: 'inline-block'}}>
          {list1.map((item, index) => (
            <div className="block done" key={index}>
              <div className="run-bar">{this.renderBlockTitle(item)}</div>
              <h2>{this.getBlockHeader(item)}</h2>
              <div className="main">
                {this.renderExBlock(item)}
              </div>
              {this.renderResultButton(index)}
            </div>
          ))}
          {this.renderCurrent()}
          {list2.map((item, index) => (
            <div className="block upcoming" key={index}>
              <div className="run-bar">{this.renderBlockTitle(item)}</div>
              <h2>{this.getBlockHeader(item)}</h2>
              <div className="main">
                {this.renderExBlock(item)}
              </div>
            </div>
          ))}
          </div>
        </div>
      )
    }
  }


  renderTimer() {
    if(this.state.view === 'timer') {
      return (
        <div className="run-timer">
          <h3 className={this.state.timer}>{this.getTimerName()}</h3>
          <h2>{this.displayTimer()}</h2>
        </div>
      )
    }
  }


  renderSetsTitle() {
    if(this.state.program.blocks[this.state.current].data.rounds > 1) {
      return (
        <h3>{lang.t('training:prog.set2')} {this.state.round}</h3>
      )
    }
  }


  renderSets() {
    if(this.state.view === 'sets') {
      return (
        <div>
          <div className="sets">
            {this.renderSetsTitle()}
            {this.state.program.blocks[this.state.current].data.exName.map((item, index) => (
              <div className={this.state.program.blocks[this.state.current].data.simple === undefined ? 'item' : 'none'} key={index}>
                <strong>{item}</strong><br/>{this.getExInfo(this.state.program.blocks[this.state.current], index)}{this.renderExNotes(this.state.program.blocks[this.state.current], index)}
              </div>
            ))}
          </div>
          <div className="btn-sets">
            <button className="btn primary" onClick={() => this.finishSet()}>{lang.t('training:button.finishset')}</button>
          </div>
        </div>
      )
    }
  }


  renderResults() {
    if(this.state.view === 'results') {
      return (
        <div>
          <div className="run-blocks">
            <div style={{height: '400px', width: 300, display: 'inline-block'}}>
              <div className="block result">
                <div className="client small static">
                  {HelperClients.getImage(this.getClient(global.spaceClient))}
                  <div className="text">{lang.t('training:running.yourresults')}</div> 
                </div>
                <div className="movements clear">
                  {this.renderExResult(0)}
                </div>
              </div>
            </div>
          </div>
          <div className="run-notes">
            <h3>{lang.t('training:label.notes')}</h3>
            <textarea value={this.state.notes} onChange={(val) => this.onChangeNotes(val)}/>
          </div>
          <div className="btn-sets">
            <button className="btn primary" onClick={() => this.saveResults()}>{lang.t('training:button.saveresults')}</button>
            <div className="sv-30 clear"></div>
            <button className="btn tertiary width-12 close" onClick={() => this.hideResults()}>{lang.t('common:button.cancel')}</button>
          </div>
        </div>
      )
    }
  }

  
  renderList() {
    if(this.state.item !== null) {
      if(this.state.view === 'timer' && this.state.timer !== 'getready' && this.state.program.blocks[this.state.current].data.type !== 4) {
        if(this.state.program.blocks[this.state.current].data.simple) {
          return (
            <div className="run-sidebar">
              <div className="list bare static">
                <h4 style={{whiteSpace: 'pre-wrap'}}>{this.state.program.blocks[this.state.current].data.notes}</h4>
              </div>
            </div>
          )
        } else {
          return (
            <div className={this.state.program.blocks[this.state.current].data.simple === undefined ? 'run-sidebar' : 'none'}>
              <h4 className="mb-20">{lang.t('training:label.movements2')}</h4>
              {this.state.program.blocks[this.state.current].data.exName.map((item, index) => (
                <div className="list bare static" style={{opacity: this.renderExOpacity(index)}} key={index}>
                  <h4>{item}</h4>
                  <div className="main" style={{width: '100%', float: 'none'}}>
                    <p>{this.getExInfo(this.state.program.blocks[this.state.current], index)}{this.renderExNotes(this.state.program.blocks[this.state.current], index)}</p>
                    <div className="clear"></div>
                  </div>
                </div>
              ))}
            </div>
          )
        }
      }
    }
  }


  renderCurrentBlock() {
    if(this.state.view === 'timer') {
      var block = this.state.program.blocks[this.state.current].data
      if(block.type === 0 || block.type === 5) {
        return (
          <h2>{this.getBlockHeader(this.state.program.blocks[this.state.current])}</h2>
        )
      } else {
        if(block.type === 1) {
          if(block.rounds > 1) {
            return (
              <h2>{lang.t('training:running.emom')} ({HelperCal.getDurationMinAlt(parseInt(block.exWork[0]))}) - {lang.t('training:running.round')} {this.state.round} {lang.t('training:prog.of')} {block.rounds}</h2>
            )
          } else {
            return (
              <h2>{lang.t('training:running.emom')} ({HelperCal.getDurationMinAlt(parseInt(block.exWork[0]))})</h2>
            )
          }
        } else if(block.type === 2) {
          if(block.emom) {
            return (
              <h2>{lang.t('training:running.intervals1')} ({HelperCal.getDurationMinAlt(parseInt(block.exWork[0]))} / {HelperCal.getDurationMinAlt(parseInt(block.exRest[0]))}) - Round {this.state.round} {lang.t('training:prog.of')} {block.rounds}</h2>
            )
          } else {
            return (
              <h2>{lang.t('training:running.intervals2')} {this.state.round} of {block.rounds}</h2>
            )
          }
          
        } else if(block.type === 3) {
          if(block.rounds > 1) {
            return (
              <h2>{lang.t('training:running.tabata')} {this.state.round} {lang.t('training:prog.of')} {block.rounds}</h2>
            )
          } else {
            return (
              <h2>{this.getBlockHeader(this.state.program.blocks[this.state.current])}</h2>
            )
          }
        }
      }
    } else if(this.state.view === 'sets') {
      return (
        <h2>{this.getBlockHeader(this.state.program.blocks[this.state.current])}</h2>
      )
    } else if(this.state.view === 'results') {
      return (
        <h2>{lang.t('training:running.results')} {this.getBlockHeader(this.state.program.blocks[this.state.results])}</h2>
      )
    }
  }


  renderButtonNotes() {
    if(this.state.program.blocks[this.state.current].data.notes !== '' && (!this.state.program.blocks[this.state.current].data.simple || this.state.program.blocks[this.state.current].data.simple === undefined)) {
      return (
        <button className="btn bnotes" onClick={() => this.showOverlayNotes()}></button>
      )
    }
  }


  renderButtons() {
    if(this.state.view === 'select' || this.state.view === 'results') {
      return (
        <div className="run-buttons">
          <button className={this.state.volume ? 'btn audio-on' : 'btn audio-off'} onClick={() => this.toggleAudio()}></button>
        </div>
      )
    } else if(this.state.view === 'sets') {
      return (
        <div className="run-buttons">
          <button className={this.state.volume ? 'btn audio-on' : 'btn audio-off'} onClick={() => this.toggleAudio()}></button>
          <button className="btn next" onClick={() => this.abortTimer()}></button>
          {this.renderButtonNotes()}
        </div>
      )
    } else {
      return (
        <div className="run-buttons">
          <button className={this.state.volume ? 'btn audio-on' : 'btn audio-off'} onClick={() => this.toggleAudio()}></button>
          <button className="btn next" onClick={() => this.abortTimer()}></button>
          <button className="btn pause" onClick={() => this.showOverlayPause()}></button>
          {this.renderButtonNotes()}
        </div>
      )
    }
  }


  renderBase() {
    if(this.state.item !== null && this.state.program !== null) {
      return (
        <div className={'run theme-'+global.spaceTheme}>
          {this.renderBlocks()}
          {this.renderTimer()}
          {this.renderSets()}
          {this.renderResults()}
          {this.renderList()}
          <div className="header">
            <div className="content">
              <div className="back lft mr-10" onClick={() => this.endSession()}>
                <div className="circle"></div>
                <div className="arrow"></div>
              </div>
              <h2>{this.getSessionName()}</h2>
            </div>
          </div>
          {this.renderButtons()}
          <div className="run-blocktitle">
            {this.renderCurrentBlock()}
          </div>
          <Message/>
          {this.renderOverlayPause()}
          {this.renderOverlayAbort()}
          {this.renderOverlayEnergy()}
          {this.renderOverlayNotes()}
        </div>
      )
    } else {
      return (
        <div className={'run theme-'+global.spaceTheme}>
          <div className="header">
            <button className="back" onClick={() => this.endSession()}></button>
          </div>
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','common','calendar','modal','training','message'])(withRouter(withAuthorization(condition)(MemberRunningPage)));